import "./carBookingSummary.scss";

import React from "react";
import dateFormat from "dateformat";

import { Link } from "react-router-dom";
import { useEffect, useContext } from "react";
import { SelectedCarContext } from "../context/selectedCar-context";
import { BookingDateTimeContext } from "../context/bookingDateTime-context";
import { UserDetailsContext } from "../context/userDetails-context";
// import { HiOutlineLocationMarker } from "react-icons/hi";

export function CarBookingSummaryPage() {
  const startdate = dateFormat(bookingDateTime?.StartDate, " dS mmmm, yyyy");
  const enddate = dateFormat(bookingDateTime?.EndDate, "dS mmmm, yyyy");
  const { selectedCar, setSelectedCar } = useContext(SelectedCarContext);
  const { bookingDateTime, setBookingDateTime } = useContext(
    BookingDateTimeContext
  );
  const { userDetails, setUserDetails } = useContext(UserDetailsContext);

  useEffect(() => {
    if (
      Object.keys(selectedCar).length !== 0 &&
      Object.keys(bookingDateTime).length !== 0 &&
      Object.keys(userDetails).length !== 0
    ) {
      localStorage.setItem("selectedCar", JSON.stringify(selectedCar));
      localStorage.setItem("bookingDateTime", JSON.stringify(bookingDateTime));
      localStorage.setItem("userDetails", JSON.stringify(userDetails));
    } else {
      setSelectedCar(JSON.parse(localStorage.getItem("selectedCar")));
      setBookingDateTime(JSON.parse(localStorage.getItem("bookingDateTime")));
      setUserDetails(JSON.parse(localStorage.getItem("userDetails")));
    }
  }, [selectedCar, bookingDateTime]);

  return (
    <div className="car-summary-main-container">
      <div className="car-summary-container">
        <div className="car-summary-image">
          <img src={selectedCar.imageurl} alt="selected-car"></img>
        </div>

        <div className="car-summary-intro-container">
          <ul>
            <li>{selectedCar.transmission}</li>
            <li>{selectedCar.fuel}</li>
            <li>Fullfilled by {selectedCar.vendor}</li>
          </ul>
          <h2>{selectedCar.name}</h2>
        </div>

        <div className="dateTime-summary-container">
          <div>
            {" "}
            <h3>Start Date</h3>
            <p>
              {startdate} {bookingDateTime?.StartTime}
            </p>
            <h3>End Date</h3>
            <p>
              {enddate} {bookingDateTime?.EndTime}
            </p>
          </div>
          {/* <div>
            <h3>Payable Amount</h3>
            <h3>₹ {selectedCar.totalRent}</h3>
          </div> */}
        </div>

        <div className="pickup-summary-container">
          <h3>
            Pick/Drop Location{" "}
            {/* <HiOutlineLocationMarker></HiOutlineLocationMarker> */}
          </h3>
          <p>{selectedCar["Pick/Drop Location"]}</p>
        </div>
      </div>

      <div className="user-details-container">
        <h2>Customer Details</h2>
        <div className="user-details">
          <div className="user-fields">
            {" "}
            <h3>Name</h3>
            <h3 id="user-field-details">{userDetails.Name}</h3>
            <h3>Mobile Number</h3>
            <h3 id="user-field-details">{userDetails.Phone}</h3>
            <h3>Email</h3>
            <h3 id="user-field-details">{userDetails.Email}</h3>
          </div>
          <div className="fare-summary-container">
            <h3>Fare Breakup</h3>
            <div className="fare-details">
              <h3>Total Amount</h3>
              <h3>₹ {selectedCar.totalRent}</h3>
            </div>
          </div>
        </div>
        <div></div>
        <p>
          By clicking on book & pay I agree with{" "}
          <Link to="/terms" className="link">
            <span
              style={{
                color: "blue",
                fontSize: "medium",
                fontWeight: "600",
                textDecoration: "underline",
              }}
            >
              {" "}
              Zymo terms and conditions{" "}
            </span>
          </Link>{" "}
          Thank you for trusting our service.
        </p>{" "}
        <button className="book-and-pay-button">Book & Pay</button>
      </div>
    </div>
  );
}
