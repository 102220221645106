import React from "react";
import classes from "./ContactUs.module.css";

function ContactUs() {
  return (
    <div className={classes.contactContainer}>
      <h1>Contact Us</h1>
      <div className={classes.contactusContent}>
        <p>
          <strong>Phone:</strong>{" "}
          <a href="tel:+91 9987933348">+91 9987933348</a>
        </p>
        <p>
          <strong>Email:</strong>{" "}
          <a href="mailto:hello@zymo.app">hello@zymo.app</a>
        </p>
      </div>
    </div>
  );
}

export default ContactUs;
