import "./AppDownloa.css";
import React from "react";
import mac from "../../assets/app-store-logo.png";
import android from "../../assets/play-store-logo.png";
import { Link } from "react-router-dom";

function AppDownloa() {
  return (
    <div className="container">
      <div className="box">
        <p id="heading">Refer your friends and be a part of Zymo</p>

        <p id="descr">
          Download the App for Exclusive Deals and Ease of Booking
        </p>

        <div className="btn">
          <div className="appDownloadOption">
            <Link
              to={`https://play.google.com/store/apps/details?id=com.letzrent.letzrentnew&referrer=utm_source%3Dplaystore%26utm_medium%3Dreferral%26utm_campaign%3Dapp_launch`}
            >
              <div className="android">
                <img src={android} alt="android" />
              </div>
            </Link>
            <Link
              to={`https://apps.apple.com/in/app/zymo-self-drive-car-rental/id1547829759?utm_source=apple_ios&utm_medium=referral&utm_campaign=app_launch`}
            >
              <div className="mac">
                <img src={mac} alt="mac" />
              </div>
            </Link>
          </div>
        </div>
      </div>

      <div className="overlay">
        <img src="Imgs/img20.jpg" alt="Zymo-Refer" />
      </div>
    </div>
  );
}

export default AppDownloa;
