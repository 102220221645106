import React from "react";
import { useEffect, useState } from "react";
import {
  createBrowserRouter,
  RouterProvider,
  BrowserRouter as Router,
} from "react-router-dom";

import BookingPage from "./pages/BookingPage";
import Home from "./pages/Home";
import RootLayout from "./pages/Root";
import ErrorPage from "./pages/Error";
import PrivacyPage from "./pages/PrivacyPage";
import AboutPage from "./pages/AboutPage";
import TermsPage from "./pages/TermsPage";
import ContactPage from "./pages/ContactPage";
import PaymentSuccessPage from "./pages/PaymentSuccessPage";
import PaymentFailPage from "./pages/PaymentFailPage";
import BlogContext from "./context/blog-context";

import { BlogsMainPage } from "./pages/blog/BlogsMainPage";
import { BlogDetailPage } from "./pages/blog/blogDetailPage";
import { CreateBlogPage } from "./pages/blog/CreateBlogPage";
import { GetDailyRentalContext } from "./context/getdailyRentals-context";
import { DailyRentalsCarsListPage } from "./pages/DailyRentalsCarsListPage";
import { SelectedCarContext } from "./context/selectedCar-context";
import { CarDetailPage } from "./pages/CarDetailPage";
import { BookingDateTimeContext } from "./context/bookingDateTime-context";
import { UserDetailsContext } from "./context/userDetails-context";
import { CarBookingSummaryPage } from "./pages/CarBookingSummaryPage";
import { CareerPage } from "./pages/careerPage/CareerPage";
import { SnackbarProvider } from "notistack";
import { CreateEditBlogPage } from "./pages/blog/createEditBlogPage";
import { CancellationPolicy } from "./pages/CancellationPolicyPage";
import { Toaster } from "react-hot-toast";

const router = createBrowserRouter([
  {
    path: "/",
    element: <RootLayout />,
    errorElement: <ErrorPage />,
    children: [
      { path: "/", element: <Home /> },
      { path: "/profile/p1", element: <BookingPage /> },
      { path: "/about", element: <AboutPage /> },
      { path: "/privacy", element: <PrivacyPage /> },
      { path: "/terms", element: <TermsPage /> },

      { path: "/blogs", element: <BlogsMainPage /> },
      { path: "/blog/:id", element: <BlogDetailPage /> },
      { path: "/cancellation-policy", element: <CancellationPolicy /> },
      { path: "/createblog/:id", element: <CreateBlogPage /> },
      { path: "/createblog", element: <CreateEditBlogPage /> },
      { path: "/contact", element: <ContactPage /> },
      { path: "/join-us", element: <CareerPage /> },

      { path: "/payment", element: <PaymentSuccessPage /> },
      { path: "/paymentFail", element: <PaymentFailPage /> },
      { path: "/dailyrentals", element: <DailyRentalsCarsListPage /> },
      { path: "/dailyrentals/car-details", element: <CarDetailPage /> },
      {
        path: "/dailyrentals/cardetails/car-booking-summary",
        element: <CarBookingSummaryPage />,
      },
    ],
  },
]);

function App() {
  const [blogsList, setBlogsList] = useState([]);
  const [bookingDateTime, setBookingDateTime] = useState({});
  const [getDailyRentalsList, setGetDailyRentalsList] = useState([]);
  const [selectedCar, setSelectedCar] = useState({});
  const [userDetails, setUserDetails] = useState({});

  useEffect(() => {
    window.SM_PAGEIMPROVE = window.SM_PAGEIMPROVE || { ready: false };
    window.addEventListener("pageimprove.initialized", function () {
      window.SM_PAGEIMPROVE.ready = true;
    });
    var script = document.createElement("script");
    script.id = "fab7ed46-f7df-4096-a0c5-a92bed0015b1";
    script.src = "https://pageimprove.io/react";
    script.type = "module";
    document.head.appendChild(script);
    window.SM_PAGEIMPROVE_DEBUG = true;
  });

  return (
    <SnackbarProvider>
      <BookingDateTimeContext.Provider
        value={{ bookingDateTime, setBookingDateTime }}
      >
        <GetDailyRentalContext.Provider
          value={{ getDailyRentalsList, setGetDailyRentalsList }}
        >
          <SelectedCarContext.Provider value={{ selectedCar, setSelectedCar }}>
            <UserDetailsContext.Provider
              value={{ userDetails, setUserDetails }}
            >
              <BlogContext.Provider value={{ blogsList, setBlogsList }}>
                <RouterProvider router={router} />
              </BlogContext.Provider>
            </UserDetailsContext.Provider>
          </SelectedCarContext.Provider>
        </GetDailyRentalContext.Provider>
      </BookingDateTimeContext.Provider>

      <Toaster
        position="top-center"
        gutter={12}
        containerStyle={{ margin: "8px" }}
        toastOptions={{
          success: {
            duration: 3000,
          },
          error: {
            duration: 5000,
          },
          style: {
            fontSize: "16px",
            maxWidth: "500px",
            padding: "16px 24px",
            backgroundColor: "#303030",
            color: "white",
          },
        }}
      />
    </SnackbarProvider>
  );
}

export default App;
