import React from "react";
import { useEffect } from "react";
import PaymentSuccess from "../components/about/PaymentSuccess";

function PaymentSuccessPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return <PaymentSuccess />;
}

export default PaymentSuccessPage;
