import React from "react";
import { useEffect } from "react";
import PaymentFail from "../components/about/PaymentFail";

function PaymentFailPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return <PaymentFail />;
}

export default PaymentFailPage;
