import React from "react";
import classes from "./Stat.module.css";
import { FaCity, FaUsers, FaRegHandshake } from "react-icons/fa";
import { AiFillCar } from "react-icons/ai";

function Stat() {
  return (
    <div className={classes.stat}>
      <h1>Why Zymo?</h1>

      <div className={classes.statContainer}>
        <div className={classes.statItem}>
          <div className={classes.statIcon}>
            <FaUsers className={classes.black} />
          </div>
          <div className={classes.statContent}>
            <h2>100,000+</h2>
            <p>Satisfied Users</p>
          </div>
        </div>

        <div className={classes.statItem}>
          <div className={classes.statIcon}>
            <AiFillCar className={classes.black} />
          </div>
          <div className={classes.statContent}>
            <h2>30,000+</h2>
            <p>Cars</p>
          </div>
        </div>

        <div className={classes.statItem}>
          <div className={classes.statIcon}>
            <FaRegHandshake className={classes.black} />
          </div>
          <div className={classes.statContent}>
            <h2>18 </h2>
            <p>Service Providers</p>
          </div>
        </div>

        <div className={classes.statItem}>
          <div className={classes.statIcon}>
            <FaCity className={classes.black} />
          </div>
          <div className={classes.statContent}>
            <h2>59</h2>
            <p>Cities</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Stat;
