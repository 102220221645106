import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React from "react";
import classes from "./Benefits.module.css";
import Slider from "react-slick";
import firstImg from "../../assets/Zymo benefits/Cost Effective.jpg";
import secondImg from "../../assets/Zymo benefits/Convenience.jpg";
import thirdImg from "../../assets/Zymo benefits/Flexibility.jpg";
import fourthImg from "../../assets/Zymo benefits/Great Prices.jpg";

function Benefits() {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 676,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  };

  return (
    <div className={classes.container}>
      <div className={classes.stat}>
        <h2>Zymo Benefits</h2>
        <Slider {...settings} className={classes.slider}>
          <div className={classes.statItem}>
            <div className={classes.imageContainer}>
              <img src={firstImg} alt={`firstImg`} loading="lazy" />
              <div className={classes.overlay}>
                <div className={classes.overlayText}>
                  <h2>Cost Effective</h2>
                  <br />
                  <p>
                    Limited or Unlimited Kilometers <br /> Package Available As
                    Per Requirement
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className={classes.statItem}>
            <div className={classes.imageContainer}>
              <img src={secondImg} alt={`secondImg`} loading="lazy" />
              <div className={classes.overlay}>
                <div className={classes.overlayText}>
                  <h2>Convenience</h2>
                  <br />
                  <p>
                    Self Pick-Up <br /> Or Home Delivered
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className={classes.statItem}>
            <div className={classes.imageContainer}>
              <img src={thirdImg} alt={`thirdImg`} loading="lazy" />
              <div className={classes.overlay}>
                <div className={classes.overlayText}>
                  <h2>Flexibility</h2>
                  <br />
                  <p>
                    Rent For Few Hours <br />
                    Or For Months
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className={classes.statItem}>
            <div className={classes.imageContainer}>
              <img src={fourthImg} alt={`fourthImg`} loading="lazy" />
              <div className={classes.overlay}>
                <div className={classes.overlayText}>
                  <h2>Great Prices</h2>
                  <br />
                  <p>
                    Get Best Prices After <br /> Comparing All The Options
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Slider>
      </div>
    </div>
  );
}

export default Benefits;
