import React from "react";
import Slider from "react-slick";
import TestimonialCard from "../UI/TestimonialCard";
import classes from "./TestimonialCarousel.module.css";

function TestimonialCarousel() {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 676,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
    ],
  };

  const testimonials = [
    {
      url: "https://play-lh.googleusercontent.com/a-/ACB-R5S1nqelZkHg1kMJIpFUD0PIB99LmBoGV74uXCaOHw=s64-rw",
      name: "Aastha Pratik",
      numStars: 5,
      // date: "10/16/2021",
      feedback:
        "Reasonable charges and smooth app. I liked the immediate response.",
    },
    {
      url: "https://play-lh.googleusercontent.com/a-/ACB-R5QtFqnv2ad_l_8gryJoBBVIC3b0QK4nxVnT6KEN-c0=s64-rw",
      name: "Vinayak More",
      numStars: 5,
      // date: "7/04/2021",
      feedback:
        "Amazing app and best platform for rental car requirements.. thank you",
    },
    {
      url: "https://play-lh.googleusercontent.com/a-/ACB-R5Sc0X8f3Uhm0iyed9As7o7Msi-8SG7Ir8j2Qp1GyA=s64-rw",
      name: "Anusha Dubey",
      numStars: 4,
      // date: "7/26/21",
      feedback:
        "Great app to compare best deals. It makes my renting experience so hassle free and the best part is I got rewards too. Easy to use and one stop destination for my renting needs.",
    },
    {
      url: "https://play-lh.googleusercontent.com/a-/ACB-R5T8IBjBc216N_fB6G-FSlBEgNJkzncANbZlQCwrHw=s64-rw",
      name: "Shrivardhan",
      numStars: 5,
      // date: "05/12/2022",
      feedback:
        "I just booked my car through this app and found it really useful. This app allows you to plan small distance trips with reasonable rates. Also the support is very authentic and supportive.",
    },
    {
      url: "https://play-lh.googleusercontent.com/a-/ACB-R5RC9Ico9JDpaprd6MbashyhFet65sbiDccxVPNrZg=s64-rw",
      name: "Sidharth Nair",
      numStars: 5,
      // date: "02/10/2023",
      feedback:
        "This app is a great initiative, it helps people compare the prices and helps us fond the best deal. I would like to specially thank Ms. Tanya and Ms. Divya for the customer service they provided me.👍",
    },
    {
      url: "https://play-lh.googleusercontent.com/a-/ACB-R5QVAvX4IHdf9SkXNQF1rMCet20HjojfVruz5Fjf9g=s64-rw",
      name: "Pakhi",
      numStars: 5,
      // date: "3/26/2021",
      feedback:
        "Very convenient to use. I use this app for all my renting requirements. I can easily compare prices for different websites and do not have to waste time searching here and there. Best offers and best deals! Highly recommended",
    },
  ];

  return (
    <div className={classes.testimonialContainer}>
      <h2>Our Customer Love Us</h2>
      <h3>We love hearing from happy customers</h3>

      <Slider {...settings} className={classes.slider}>
        {testimonials.map((testimonial) => (
          <div className={classes.slides}>
            <TestimonialCard
              profileImg={testimonial.url}
              name={testimonial.name}
              numStar={testimonial.numStars}
              date={testimonial.date}
              feedback={testimonial.feedback}
            />
          </div>
        ))}
      </Slider>
    </div>
  );
}

export default TestimonialCarousel;
