import React from "react";
import About from "../components/about/About";

import { useEffect } from "react";
import { Helmet } from "react-helmet";

function AboutPage() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  return (
    <div>
      <Helmet>
        <link rel="canonical" href="https://zymo.app/about-us" />
      </Helmet>
      <About />;
    </div>
  );
}

export default AboutPage;
