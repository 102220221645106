import React from "react";
import classes from "./About.module.css";

function About() {
  return (
    <div className={classes.aboutContainer}>
      <h1>About Us</h1>
      <br />
      <hr></hr>
      <br />

      <p>Welcome to Zymo – where your journey meets its perfect match! 🚗✨</p><br/>

      <p>
        In the bustling world of wheels and dreams, Zymo is not just a company;
        it's your trusted companion in the world of mobility. We started with
        the simple idea of making car rental as easy as a walk in the park. But
        guess what? We didn't stop there!. Imagine a place where every road trip
        is a story waiting to be told, and every drive is an adventure waiting
        to unfold. That's Zymo for you!
      </p>
      <br />

      <h3>Our Story:</h3>
      <p>
        {" "}
        Once upon a time, our founder (who loves roads as much as you do) dreamt
        of a world where finding the perfect car for your journey is as easy as
        saying 'hello.' That dream became Zymo, a magical place where cars and
        journeys find their happily ever after.
      </p>
      <br />

      <h3>What We Do:</h3>
      <p>
        {" "}
        From zipping through cityscapes in a zippy hatchback to conquering
        mountains in a sturdy SUV, Zymo brings you the keys to your dream drive.
        We're not just about cars; we're about the stories you create behind the
        wheel.
      </p>
      <br />

      <h3> The Next Chapter – Zymo Cars for Sale:</h3>
      <p>
        But wait, there's more to our story! We've embarked on a new chapter,
        one where you can make those dream drives a permanent reality. Zymo is
        thrilled to introduce car sales! Now, you can find not just the perfect
        ride for the weekend but the perfect companion for life.
      </p>
      <br />

      <p>
        Welcome to Zymo, India's largest aggregator for self-drive car rentals.
        With over 30,000 cars listed on our platform, we are the go-to
        destination for individuals looking for an exceptional self-drive rental
        experience in over 50 cities. Our goal is to be a lifestyle brand that
        is trusted by millions of Indians, and we strive to become the most
        beloved brand in the country.
      </p>

      <br></br>
      <p>
        At Zymo, we understand that building trust is critical to our success.
        Therefore, we focus on two key areas: transparent pricing and excellent
        customer service. Our pricing is always upfront, so you never have to
        worry about hidden fees or surprises. Our commitment to exceptional
        customer service ensures that every interaction you have with us is
        pleasant and hassle-free.
      </p>

      <br></br>
      <p>
        Whether you need a self-drive car for a minimum of 8 hours or several
        months, we have you covered. Our vast collection of cars includes
        hatchbacks, sedans, SUVs, and luxury vehicles. No matter what your needs
        or preferences are, you can find the perfect car for your next adventure
        on our platform.
      </p>

      <br></br>
      <p>
        What sets us apart from other rental platforms is our price comparison
        feature, which allows you to choose from the maximum options at the best
        prices. Our platform makes it easy to compare rental options, so you can
        make an informed decision that meets your needs and budget.
      </p>

      <br></br>
      <p>
        We take pride in providing a seamless, stress-free rental experience,
        from the moment you book your car to the end of your rental period. Our
        app is the highest rated in the category on both platforms, reflecting
        our commitment to providing the best service possible.
      </p>
      <br />
      <br />

      <h3>Why Choose Zymo?</h3>
      <br></br>
      <p>
        Variety: Whether you're renting or buying, we've got a fleet that suits
        every style and purpose. Easy Peasy: Booking a car or buying one, it's
        all as simple as a click. We believe in hassle-free experiences.
        Affordable Adventures: Quality doesn't have to break the bank. With
        Zymo, every journey is budget-friendly. Join Our Adventure: Ready to hit
        the road with us? Zymo is not just a service; it's a community of
        explorers, dreamers, and those who find joy in every turn. Your journey
        begins with us, and the road ahead is filled with endless possibilities.
        At Zymo, we're not just selling cars; we're selling dreams, adventures,
        and a lifetime of memories. Come, let's drive into the future together!
        🌟🚗💨
      </p>

      <br></br>
      <p>Thank you for choosing Zymo. We look forward to serving you soon.</p>
      <br />
      <br />

      <h2> Rent Self Drive Cars with the Best Car Rental App in India</h2>
      <br />

      <p>
        Are you tired of relying on public transportation or borrowing cars from
        friends and family? Look no further than the best car rental app in
        India - Zymo! Our self drive car rental service is available in Pune,
        Delhi, Mumbai, Bangalore, Chennai and other major cities.
      </p>
      <br />

      <p>
        With Zymo, you can easily rent a self drive car and enjoy the freedom to
        explore your destination at your own pace. Our user-friendly app makes
        it easy to find and book the perfect car for your needs. Plus, our
        competitive prices and flexible rental options make us the best car
        rental sharing app in India
      </p>
      <br />

      <p>
        Say goodbye to the hassle of traditional car rental companies and hello
        to the convenience of Zymo's car rental application. With a range of
        options to choose from, you'll find the perfect self drive car hire for
        your next adventure.
      </p>

      <br />
      <p>
        Don't let transportation woes hold you back. Download Zymo today and
        experience the joy of driving your own self drive car rental.
      </p>
    </div>
  );
}

export default About;
