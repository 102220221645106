import "./dailyRentalCarCard.scss";
import React from "react";
import Image from "../../../src/assets/CarVendorsLogo/wowcarz.png";
import Image1 from "../../../src/assets/CarVendorsLogo/myChoiz.png";

import { SelectedCarContext } from "../../context/selectedCar-context";
import { useNavigate } from "react-router-dom";

export function DailyRentalCarCard({ car }) {
  const navigate = useNavigate();
  const { selectedCar, setSelectedCar } = React.useContext(SelectedCarContext);

  function handleClick() {
    setSelectedCar(car);
    navigate("/dailyrentals/car-details");
  }

  return (
    <div className="car-card-container" onClick={handleClick}>
      <img src={car.imageurl || car.VehicleBrandImageName} alt={car.name}></img>

      <div className="car-briefIntro-container">
        {" "}
        <div>
          <h4>
            <strong>{car.name || car.BrandName}</strong>
          </h4>
          <p>{car.seats || car.SeatingCapacity} seater</p>
          <img src={car.BrandKey ? Image1 : Image} alt={car.vendor}></img>
        </div>
        <div>
          <p>Starts at</p>
          <h3>
            <strong> ₹ {car.totalRent || car.TotalExpCharge}</strong>
            <br />
            <h6 style={{ color: "rgb(84, 82, 82);", fontWeight: "normal" }}>
              (GST incl)
            </h6>
          </h3>
        </div>
      </div>
    </div>
  );
}
