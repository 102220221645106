import React from "react";
import classes from "./Booking.module.css";
import Card from "../UI/Card";

const bookingServices = [
  {
    id: 1,
    src: "https://miro.medium.com/max/1069/1*zylQyQHW8-zUHriAWhGBkw.png",
    content: "Self Drive Cars",
  },
  {
    id: 2,
    src: "https://www.revv.co.in/blogs/wp-content/uploads/2020/07/advantages-of-car-rentals.jpg",
    content: "Monthly Car Rental",
  },
  {
    id: 3,
    src: "https://www.mymove.com/wp-content/uploads/2020/10/GettyImages-548214103-scaled.jpg",
    content: "Rent Pay",
  },
];

function Booking() {
  return (
    <div className={classes.bookingContainer}>
      <h1>My Bookings</h1>

      {bookingServices.map((service) => (
        <Card>
          <span>{service.content}</span>
          <img
            src={service.src}
            alt="serviceImage"
            className={classes.serviceImg}
          />
        </Card>
      ))}
    </div>
  );
}

export default Booking;
