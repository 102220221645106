import React from "react";
import classes from "./HeroSection.module.css";
import mac from "../../assets/app-store-logo.png";
import android from "../../assets/play-store-logo.png";
import HeroBG from "../../assets/HeroSection/heroSecImage.jpg";
import { Link } from "react-router-dom";
// import Select from "react-select";

// const options = [
//   { value: "Mumbai", label: "Mumbai" },
//   { value: "Delhi", label: "Delhi" },
//   { value: "Bangalore", label: "Bangalore" },
//   { value: "Chennai", label: "Chennai" },
//   { value: "Hyderabad", label: "Hyderabad" },
//   { value: "Pune", label: "Pune" },
//   { value: "Mangalore", label: "Mangalore" },
//   { value: "Dombivili", label: "Dombivili" },
// ];

function HeroSection() {
  // const [selectedOption, setSelectedOption] = useState(null);
  // const navigate = useNavigate();

  // function handleSelectChange(selectedOption) {
  //   setSelectedOption(selectedOption);
  // };

  // function handleSearch() {
  //   if (selectedOption) {
  //     navigate(`/cars/${selectedOption.value}`);
  //   }
  // };

  return (
    <div className={classes.temp}>
      <div className={classes.heroContainer}>
        <div className={classes.heroHeading}>
          {/* <img
            src={HeroBG}
            className={classes.imageSection}
            alt="Hero Background"
          /> */}

          <img
            src={HeroBG}
            className={classes.imageSection}
            alt="Hero Background"
            loading="lazy"
            sizes="(max-width: 600px) 100vw, (max-width: 1200px) 50vw, 800px"
          />

          <Link
            to="https://apps.apple.com/in/app/letzrent-self-drive-car/id1547829759"
            style={{
              position: "absolute",
              top: "64%",
              left: "2.5%",
              width: "23%",
              height: "14%",
              display: "block",
              cursor: "pointer",
            }}
          />

          <Link
            to="https://play.google.com/store/apps/details?id=com.letzrent.letzrentnew"
            style={{
              position: "absolute",
              top: "64%",
              left: "28%",
              width: "25%",
              height: "14%",
              display: "block",
              cursor: "pointer",
            }}
          />

          {/* <div>
            <Link
              to={`https://apps.apple.com/in/app/zymo-self-drive-car-rental/id1547829759?utm_source=apple_ios&utm_medium=referral&utm_campaign=app_launch`}
              className={classes.macFloat}
            >
              <div className={classes.mac}>
                <img src={mac} alt="mac" />
              </div>
            </Link>

            <Link
              to={`https://play.google.com/store/apps/details?id=com.letzrent.letzrentnew&referrer=utm_source%3Dplaystore%26utm_medium%3Dreferral%26utm_campaign%3Dapp_launch`}
              className={classes.androidFloat}
            >
              <div className={classes.android}>
                <img src={android} alt="android" />
              </div>
            </Link>
          </div> */}
        </div>
      </div>
    </div>

    // <div className={classes.temp}>
    //   <div className={classes.heroContainer}>
    //     <div className={classes.heroHeading}>
    //       <img src={HeroBG} className={classes.imageSection} />
    //       <h1>India's Largest Aggregator For Self Drive Car Rental</h1>
    //       <p>Highest Rated Mobile App In Category*</p>
    //       <h2>Download Zymo App and get voucher worth Rs 100/-</h2>
    //     </div>

    //     <div className={classes.appDownloadOption}>
    //       <Link
    //         to={`https://play.google.com/store/apps/details?id=com.letzrent.letzrentnew&referrer=utm_source%3Dplaystore%26utm_medium%3Dreferral%26utm_campaign%3Dapp_launch`}
    //       >
    //         <div className={classes.android}>
    //           <img src={android} alt="android" />
    //         </div>
    //       </Link>
    //       <Link
    //         to={`https://apps.apple.com/in/app/zymo-self-drive-car-rental/id1547829759?utm_source=apple_ios&utm_medium=referral&utm_campaign=app_launch`}
    //       >
    //         <div className={classes.mac}>
    //           <img src={mac} alt="mac" />
    //         </div>
    //       </Link>
    //     </div>

    //     <div className={classes.dateLocation}>
    //       <div className={classes.dateLocationContainer}>
    //         <div className={classes.location}>
    //           <h3>Location</h3>
    //           <Select
    //             value={selectedOption}
    //             onChange={handleSelectChange}
    //             options={options}
    //             placeholder="Enter Location"
    //             isSearchable
    //           />
    //         </div>
    //         <div className={classes.startDate}>
    //           <h3>Start Date</h3>
    //           <input type="datetime-local" placeholder="mm/dd/yyyy" />
    //         </div>
    //         <div className={classes.returnDate}>
    //           <h3>Return Date</h3>
    //           <input type="datetime-local" placeholder="mm/dd/yyyy" />
    //         </div>
    //         <div className={classes.searchButton}>
    //           <button onClick={handleSearch}>Get Car</button>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
}

export default HeroSection;

// import React from "react";
// import { Link } from "react-router-dom";
// import classes from "./HeroSection.module.css";
// import mac from "../../assets/mac.svg";
// import android from "../../assets/android.svg";
// import { useState, useEffect } from "react";
// import Select from "react-select";

// const options = [
//   { value: "Mumbai", label: "Mumbai" },
//   { value: "Delhi", label: "Delhi" },
//   { value: "Bangalore", label: "Bangalore" },
//   { value: "Chennai", label: "Chennai" },
//   { value: "Hyderabad", label: "Hyderabad" },
//   { value: "Pune", label: "Pune" },
//   { value: "Mangalore", label: "Mangalore" },
//   { value: "Dombivili", label: "Dombivili" },
//   { value: "Palava", label: "Palava" },
//   { value: "Thane", label: "Thane" },
//   { value: "Amritsar", label: "Amritsar" },
//   { value: "Goa", label: "Goa" },
//   { value: "Lucknow", label: "Lucknow" },
//   { value: "Bhopal", label: "Bhopal" },
//   { value: "Guwahati", label: "Guwahati" },
//   { value: "Indore", label: "Indore" },
//   { value: "Cochin", label: "Cochin" },
//   { value: "Bangalore", label: "Bangalore" },
//   { value: "Mysore", label: "Mysore" },
//   { value: "Modinagar", label: "Modinagar" },
//   { value: "Muradnagar", label: "Muradnagar" },
//   { value: "Gurugram", label: "Gurugram" },
//   { value: "Noida", label: "Noida" },
//   { value: "Kolkata", label: "Kolkata" },
//   { value: "Ahmedabad", label: "Ahmedabad" },
//   { value: "Bhubaneswar", label: "Bhubaneswar" },
//   { value: "Chandigarh", label: "Chandigarh" },
//   { value: "Coimbatore", label: "Coimbatore" },
//   { value: "Jaipur", label: "Jaipur" },
//   { value: "Kochi", label: "Kochi" },
//   { value: "Nashik", label: "Nashik" },
//   { value: "Madurai", label: "Madurai" },
//   { value: "Ghaziabad", label: "Ghaziabad" },
//   { value: "Merrut", label: "Merrut" },
//   { value: "Nagpur", label: "Nagpur" },
//   { value: "Siliguri", label: "Siliguri" },
//   { value: "Trichy", label: "Trichy" },
//   { value: "Vadodara", label: "Vadodara" },
//   { value: "Vijaywada", label: "Vijaywada" },
//   { value: "Vijag", label: "Vijag" },
//   { value: "Udupi", label: "Udupi" },
//   { value: "Vishakapatnam", label: "Vishakapatnam" },
//   { value: "Udaipur", label: "Udaipur" },
//   { value: "Jodhpur", label: "Jodhpur" },
//   { value: "Haridwar", label: "Haridwar" },
//   { value: "Rishikesh", label: "Rishikesh" },
// ];

// const HeroSection = () => {
//   const [selectedOption, setSelectedOption] = useState(null);

//   const handleSelectChange = (selectedOption) => {
//     setSelectedOption(selectedOption);
//   };

//   return (
//     <div className={classes.temp}>
//       <div className={classes.heroContainer}>
//         <div className={classes.heroHeading}>
//           <h1>India's Largest Aggregator For Self Drive Car Rental</h1>
//           <h1>Highest Rated Mobile App In Category*</h1>
//         </div>
//         <div className={classes.appDownloadOption}>
//           <Link
//             to={`https://play.google.com/store/apps/details?id=com.letzrent.letzrentnew&referrer=utm_source%3Dplaystore%26utm_medium%3Dreferral%26utm_campaign%3Dapp_launch`}
//           >
//             <div className={classes.android}>
//               <img src={android} alt="android" />
//               <h2> Download the App</h2>
//             </div>
//           </Link>
//           <Link
//             to={`https://apps.apple.com/in/app/zymo-self-drive-car-rental/id1547829759?utm_source=apple_ios&utm_medium=referral&utm_campaign=app_launch`}
//           >
//             <div className={classes.mac}>
//               <img src={mac} alt="mac" />
//               <h2> Download the App</h2>
//             </div>
//           </Link>
//         </div>

//         <div className={classes.dateLocation}>
//           <div className={classes.dateLocationContainer}>
//             <div className={classes.location}>
//               <h3>Location</h3>
//               <Select
//                 value={selectedOption}
//                 onChange={handleSelectChange}
//                 options={options}
//                 placeholder="Enter Location"
//                 isSearchable
//               />
//             </div>
//             <div className={classes.startDate}>
//               <h3>Start Date</h3>
//               <input type="datetime-local" placeholder="mm/dd/yyyy" />
//             </div>
//             <div className={classes.returnDate}>
//               <h3>Return Date</h3>
//               <input type="datetime-local" placeholder="mm/dd/yyyy" />
//             </div>
//             <div className={classes.searchButton}>
//               <button>Get Car</button>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default HeroSection;

// import React from "react";
// import { Link } from "react-router-dom";
// import classes from "./HeroSection.module.css";
// import mac from "../../assets/mac.svg";
// import android from "../../assets/android.svg";
// import { useState } from "react";
// import Select from "react-select";
// import { useNavigate } from "react-router-dom";

// const options = [
//   { value: "Mumbai", label: "Mumbai" },
//   { value: "Delhi", label: "Delhi" },
//   { value: "Bangalore", label: "Bangalore" },
//   { value: "Chennai", label: "Chennai" },
//   { value: "Hyderabad", label: "Hyderabad" },
//   { value: "Pune", label: "Pune" },
//   { value: "Mangalore", label: "Mangalore" },
//   { value: "Dombivili", label: "Dombivili" },
// ];

// const HeroSection = () => {
//   const [selectedOption, setSelectedOption] = useState(null);
//   const navigate = useNavigate();

//   const handleSelectChange = (selectedOption) => {
//     setSelectedOption(selectedOption);
//   };

//   const handleButtonClick = () => {
//     if (selectedOption) {
//       navigate(`/cars/${selectedOption.value}`);
//     }
//   };

//   return (
//     <div className={classes.temp}>
//       <div className={classes.heroContainer}>
//         <div className={classes.heroHeading}>
//           <h1>India's Largest Aggregator For Self Drive Car Rental</h1>
//           <h1>Highest Rated Mobile App In Category*</h1>
//         </div>
//         <div className={classes.appDownloadOption}>
//           <Link
//             to={`https://play.google.com/store/apps/details?id=com.letzrent.letzrentnew&referrer=utm_source%3Dplaystore%26utm_medium%3Dreferral%26utm_campaign%3Dapp_launch`}
//           >
//             <div className={classes.android}>
//               <img src={android} alt="" />
//               <h2> Download the App</h2>
//             </div>
//           </Link>
//           <Link
//             to={`https://apps.apple.com/in/app/zymo-self-drive-car-rental/id1547829759?utm_source=apple_ios&utm_medium=referral&utm_campaign=app_launch`}
//           >
//             <div className={classes.mac}>
//               <img src={mac} alt="mac" />
//               <h2> Download the App</h2>
//             </div>
//           </Link>
//         </div>

//         <div className={classes.dateLocation}>
//           <div className={classes.dateLocationContainer}>
//             <div className={classes.location}>
//               <h3>Location</h3>
//               <Select
//                 value={selectedOption}
//                 onChange={handleSelectChange}
//                 options={options}
//                 placeholder="Enter Location"
//                 isSearchable
//               />
//             </div>
//             <div className={classes.startDate}>
//               <h3>Start Date</h3>
//               <input type="datetime-local" placeholder="mm/dd/yyyy" />
//             </div>
//             <div className={classes.returnDate}>
//               <h3>Return Date</h3>
//               <input type="datetime-local" placeholder="mm/dd/yyyy" />
//             </div>
//             <div className={classes.searchButton}>
//               <button onClick={handleButtonClick}>Get Car</button>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default HeroSection;

// import React from "react";
// import { Link, useNavigate } from "react-router-dom";
// import classes from "./HeroSection.module.css";
// import mac from "../../assets/mac.svg";
// import android from "../../assets/android.svg";
// import { useState, useEffect } from "react";
// import Select from "react-select";

// const options = [  { value: "Mumbai", label: "Mumbai" },  { value: "Delhi", label: "Delhi" },  { value: "Bangalore", label: "Bangalore" },  { value: "Chennai", label: "Chennai" },  { value: "Hyderabad", label: "Hyderabad" },  { value: "Pune", label: "Pune" },  { value: "Mangalore", label: "Mangalore" },  { value: "Dombivili", label: "Dombivili" },  ];

// const HeroSection = () => {
//   const [selectedOption, setSelectedOption] = useState(null);
//   const [startDate, setStartDate] = useState("");
//   const [returnDate, setReturnDate] = useState("");

//   const navigate = useNavigate();

//   const handleSelectChange = (selectedOption) => {
//     setSelectedOption(selectedOption);
//   };

//   const handleStartDateChange = (e) => {
//     setStartDate(e.target.value);
//   };

//   const handleReturnDateChange = (e) => {
//     setReturnDate(e.target.value);
//   };

//   const handleGetCarClick = () => {
//     navigate({
//       pathname: "/car-list",
//       state: {
//         location: selectedOption,
//         startDate: startDate,
//         returnDate: returnDate,
//       },
//     });
//   };

//   return (
//     <div className={classes.temp}>
//       <div className={classes.heroContainer}>
//         <div className={classes.heroHeading}>
//           <h1>India's Largest Aggregator For Self Drive Car Rental</h1>
//           <h1>Highest Rated Mobile App In Category*</h1>
//         </div>
//         <div className={classes.appDownloadOption}>
//           <Link
//             to={`https://play.google.com/store/apps/details?id=com.letzrent.letzrentnew&referrer=utm_source%3Dplaystore%26utm_medium%3Dreferral%26utm_campaign%3Dapp_launch`}
//           >
//             <div className={classes.android}>
//               <img src={android} alt="android" />
//               <h2> Download the App</h2>
//             </div>
//           </Link>
//           <Link
//             to={`https://apps.apple.com/in/app/zymo-self-drive-car-rental/id1547829759?utm_source=apple_ios&utm_medium=referral&utm_campaign=app_launch9`}
//           >
//             <div className={classes.mac}>
//               <img src={mac} alt="mac" />
//               <h2> Download the App</h2>
//             </div>
//           </Link>
//         </div>

//         <div className={classes.dateLocation}>
//           <div className={classes.dateLocationContainer}>
//             <div className={classes.location}>
//               <h3>Location</h3>
//               <Select
//                 value={selectedOption}
//                 onChange={handleSelectChange}
//                 options={options}
//                 placeholder="Enter Location"
//                 isSearchable
//               />
//             </div>
//             <div className={classes.startDate}>
//               <h3>Start Date</h3>
//               <input type="datetime-local" placeholder="mm/dd/yyyy" />
//             </div>
//             <div className={classes.returnDate}>
//               <h3>Return Date</h3>
//               <input type="datetime-local" placeholder="mm/dd/yyyy" />
//             </div>
//             <div className={classes.searchButton}>
//               <button onClick={handleGetCarClick}>Get Car</button>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default HeroSection;
