import "./Footer1.module.css";
import React from "react";
import classes from "./Footer1.module.css";
import AppStore from "../../assets/FooterImage/app-store-logo.png";
import PlayStore from "../../assets/FooterImage/play-store-logo.png";
import SecurePayments from "../../assets/FooterImage/pay.png";
// import ZymoLogo from "../../assets/FooterImage/zymonewlogo.png";
import ZymoLogo from "../../assets/zymoNewLogo.png";
import {
  BsFacebook,
  BsInstagram,
  BsLinkedin,
  BsTwitter,
  BsYoutube,
} from "react-icons/bs";

import { Link, NavLink } from "react-router-dom";

function Footer1() {
  function scrollToTop() {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  function scrollToElement(elementId) {
    const element = document.getElementById(elementId);

    if (element) {
      const offsetTop =
        element.getBoundingClientRect().top + window.scrollY - 80;
      window.scrollTo({ top: offsetTop, behavior: "smooth" });
    }
  }

  return (
    <footer className={classes.sectionP1}>
      <div className={classes.col}>
        <a href="https://www.zymo.app/">
          <img className={classes.logo} src={ZymoLogo} alt="Zymo Logo" />
        </a>
        <h4>Contact</h4>
        <p>
          <strong>Email:</strong>{" "}
          <a href="mailto:hello@zymo.app">hello@zymo.app</a>
        </p>
        <p>
          <strong>Phone:</strong>{" "}
          <a href="tel:+91 9987933348">+91 9987933348</a>
        </p>

        <div className={classes.follow}>
          <h4>Follow us</h4>
          <div className={classes.icon}>
            <a
              className={classes.iconATag}
              href="https://www.facebook.com/zymo.official/"
            >
              <BsFacebook className={classes.footerIcon} />
            </a>
            <a
              className={classes.iconATag}
              href="https://www.instagram.com/zymo.app/"
            >
              <BsInstagram className={classes.footerIcon} />
            </a>
            <a
              className={classes.iconATag}
              href="https://www.linkedin.com/company/zymoapp/"
            >
              <BsLinkedin className={classes.footerIcon} />
            </a>
            <a className={classes.iconATag} href="https://twitter.com/Zymoapp/">
              <BsTwitter className={classes.footerIcon} />
            </a>
            <a
              className={classes.iconATag}
              href="https://www.youtube.com/@zymoapp/"
            >
              <BsYoutube className={classes.footerIcon} />
            </a>
          </div>
        </div>
      </div>

      <div className={classes.col}>
        <h4>About</h4>
        {/* <Link to='./about' /> */}
        <NavLink to="/" onClick={scrollToTop}>
          Deals
        </NavLink>
        <NavLink to="/" onClick={() => scrollToElement("fleet")}>
          Fleet
        </NavLink>
        <NavLink to="/about">About Us</NavLink>
        <NavLink to="/contact">Contact Us</NavLink>
        <NavLink to="/join-us">Career</NavLink>
      </div>

      <div className={classes.col}>
        <h4>Features</h4>
        <NavLink to="/blogs">Blogs</NavLink>
        <NavLink to="/privacy">Privacy Policy</NavLink>
        <NavLink to="/terms">Terms of Service</NavLink>
        <NavLink to="/cancellation-policy">Cancellation Policy</NavLink>
      </div>

      <div className={`${classes.col} ${classes.install}`}>
        <h4>Install App</h4>
        <p>From App Store or Google Play</p>
        <div className={classes.row}>
          <Link
            to={`https://play.google.com/store/apps/details?id=com.letzrent.letzrentnew&referrer=utm_source%3Dplaystore%26utm_medium%3Dreferral%26utm_campaign%3Dapp_launch`}
          >
            <div className={classes.android}>
              <img src={PlayStore} alt="android" width={180} />
            </div>
          </Link>
          <Link
            to={`https://apps.apple.com/in/app/zymo-self-drive-car-rental/id1547829759?utm_source=apple_ios&utm_medium=referral&utm_campaign=app_launch`}
          >
            <div className={classes.mac}>
              <img src={AppStore} alt="mac" width={180} />
            </div>
          </Link>
        </div>
        <p>Secured Payment Gateway</p>
        <img src={SecurePayments} alt="Secured Payment Gateway" />
      </div>

      <div className={classes.copyright}>
        <p>
          Copyright © 2023 Zep Tepi Technologies Pvt Ltd. All Rights Reserved.
        </p>
      </div>
    </footer>
  );
}

export default Footer1;
