export const ZymoLogo = (props) => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    // xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    width="100%"
    viewBox="0 0 1850 477"
    enable-background="new 0 0 1850 477"
    // xml:space="preserve"
  >
    <path
      fill="#FFFFFF"
      opacity="1.000000"
      stroke="none"
      d="
M1200.000000,478.000000 
	C800.000000,478.000000 400.500000,478.000000 1.000000,478.000000 
	C1.000000,319.000000 1.000000,160.000000 1.000000,1.000000 
	C617.666687,1.000000 1234.333374,1.000000 1851.000000,1.000000 
	C1851.000000,160.000000 1851.000000,319.000000 1851.000000,478.000000 
	C1634.166626,478.000000 1417.333374,478.000000 1200.000000,478.000000 
M205.918594,395.116333 
	C210.612289,395.059357 215.305969,395.002380 220.923843,395.116119 
	C230.948990,395.059235 240.974121,395.002319 251.923187,395.118866 
	C260.615265,395.061371 269.307373,395.003906 278.923553,395.117767 
	C288.615967,395.060516 298.308380,395.003235 308.924896,395.116516 
	C318.283234,395.060059 327.641541,395.003601 337.924316,395.116760 
	C346.514343,395.116760 355.104401,395.116760 363.694702,395.116760 
	C363.694702,378.037567 363.694702,361.625305 363.694702,345.052887 
	C354.594055,345.052887 345.796967,345.052887 336.075226,344.885315 
	C326.717072,344.941559 317.358948,344.997803 307.076050,344.886444 
	C297.383850,344.942139 287.691650,344.997833 277.075012,344.884705 
	C268.383087,344.941315 259.691162,344.997955 250.074844,344.886322 
	C240.049789,344.942413 230.024719,344.998505 219.080795,344.882507 
	C214.387039,344.939911 209.693268,344.997314 204.104294,344.884003 
	C201.069611,344.940277 198.034927,344.996552 194.110916,344.882782 
	C190.916260,344.882782 187.721619,344.882782 183.656708,344.882782 
	C190.534378,336.971313 196.610184,329.982239 203.085068,322.424805 
	C208.714798,316.250916 214.344513,310.076996 220.557159,303.595154 
	C233.808350,288.783661 247.059525,273.972198 260.868500,258.819275 
	C269.287872,249.329117 277.707214,239.838943 286.737152,230.025665 
	C295.614594,220.076767 304.492035,210.127853 313.935638,199.864456 
	C321.947327,190.889130 329.959045,181.913803 338.550568,172.617706 
	C344.448822,166.106430 350.363617,159.610031 356.241150,153.080078 
	C363.451965,145.068848 366.668518,135.911148 362.130981,125.650627 
	C357.422546,115.003548 348.522064,109.840569 336.079651,108.892128 
	C328.053345,108.946358 320.027039,109.000587 311.075989,108.887177 
	C302.717255,108.942703 294.358551,108.998230 285.075348,108.885178 
	C276.383392,108.941353 267.691406,108.997528 258.074890,108.885414 
	C246.049225,108.941086 234.023575,108.996758 221.079025,108.882507 
	C216.385269,108.939613 211.691513,108.996727 206.082108,108.882500 
	C201.720596,108.939972 197.359070,108.997444 192.078796,108.884171 
	C187.385300,108.940811 182.691803,108.997452 177.079514,108.883125 
	C172.386337,108.939667 167.693176,108.996208 162.077988,108.881996 
	C156.718231,108.938560 151.358490,108.995132 145.074768,108.882469 
	C138.382690,108.938766 131.690598,108.995056 124.074478,108.881401 
	C116.819954,108.881401 109.565437,108.881401 102.232391,108.881401 
	C102.232391,126.521729 102.232391,143.651810 102.232391,160.836090 
	C110.084877,160.836090 117.540939,160.836090 125.925278,160.976578 
	C132.615860,160.929642 139.306458,160.882706 146.923538,160.975998 
	C152.282135,160.929031 157.640717,160.882065 163.922745,160.973587 
	C168.614838,160.927017 173.306946,160.880463 178.922180,160.973145 
	C183.613754,160.926682 188.305313,160.880219 193.917953,160.972443 
	C198.278366,160.926178 202.638779,160.879913 207.922363,160.972198 
	C212.614395,160.926666 217.306427,160.881134 222.926666,160.974945 
	C234.950699,160.928268 246.974716,160.881592 259.919830,160.974792 
	C264.398102,160.974792 268.876373,160.974792 274.058716,160.974792 
	C268.220367,167.896866 263.111969,173.953445 257.404175,180.303040 
	C243.939774,195.869095 230.475372,211.435165 216.409500,227.294708 
	C210.900452,233.747437 205.391403,240.200150 199.297928,247.017944 
	C193.200012,254.015106 187.102097,261.012299 180.356232,268.211761 
	C174.569275,275.136383 168.782303,282.061035 162.375717,289.260834 
	C156.137634,296.583527 149.899551,303.906189 143.045197,311.573364 
	C136.675385,318.990540 130.305573,326.407745 123.324196,334.129852 
	C117.226822,341.139954 110.789238,347.887421 105.121742,355.229340 
	C98.638519,363.627991 97.060120,373.221741 102.242889,382.804016 
	C106.772896,391.179504 114.228706,395.411743 124.923058,395.122650 
	C131.282455,395.064880 137.641846,395.007080 144.924164,395.121979 
	C150.949844,395.063812 156.975540,395.005646 163.924423,395.118317 
	C169.949524,395.061127 175.974640,395.003937 182.913010,395.118256 
	C186.942093,395.061218 190.971161,395.004181 195.895782,395.116516 
	C198.930359,395.059448 201.964935,395.002380 205.918594,395.116333 
M566.313293,79.108101 
	C562.176147,82.277740 558.039001,85.447372 553.261719,88.896477 
	C546.085205,95.686218 538.908752,102.475960 531.107666,109.604446 
	C525.383118,116.690056 519.658569,123.775665 513.344482,131.218491 
	C507.403900,141.406464 501.463287,151.594437 495.099487,162.303085 
	C475.383087,203.901871 470.090515,247.367020 478.634369,292.497742 
	C481.971741,310.126373 487.266846,327.187256 495.884460,343.836456 
	C501.938263,353.859711 507.992065,363.882965 514.210815,374.570160 
	C520.084412,381.582275 525.958069,388.594391 532.094788,396.175171 
	C538.084534,401.719391 544.074219,407.263611 550.421875,413.380524 
	C557.899597,418.752350 565.377380,424.124176 573.284485,429.970947 
	C580.182312,433.674652 587.080139,437.378357 594.438049,441.594147 
	C600.902344,444.253754 607.366638,446.913391 614.433960,449.919373 
	C621.622620,451.940613 628.811340,453.961853 636.656982,456.390381 
	C645.437683,457.848511 654.218445,459.306610 663.884216,460.965485 
	C666.531128,461.185181 669.186523,461.665955 671.823486,461.580292 
	C684.552307,461.166504 697.276062,460.600250 710.866089,460.178375 
	C720.910522,458.094086 730.954956,456.009827 741.774963,453.960754 
	C750.537415,450.713440 759.299866,447.466095 768.798523,444.120697 
	C778.198425,439.020203 787.598389,433.919678 797.647278,428.654938 
	C807.472412,420.886627 817.297485,413.118317 827.731506,405.030823 
	C839.297119,394.303284 849.632080,382.535492 858.627686,368.772308 
	C892.735596,316.343109 902.377869,259.745789 887.127319,199.085785 
	C881.375366,176.206940 871.922058,154.878281 857.987305,134.953705 
	C846.740417,118.390053 833.650085,103.509232 817.600098,90.463821 
	C805.052307,80.373367 791.898315,71.229134 776.663025,64.106926 
	C764.107910,59.434593 751.552856,54.762260 738.313660,49.741165 
	C728.208557,47.875740 718.103455,46.010315 707.105286,43.936134 
	C695.737122,43.672520 684.368896,43.408905 672.123596,43.024559 
	C666.415710,43.681171 660.707886,44.337784 654.151489,44.912266 
	C647.102234,46.338398 640.052979,47.764530 632.238281,49.166317 
	C625.471741,51.398952 618.705139,53.631592 611.183777,55.910122 
	C604.790771,58.707340 598.397766,61.504562 591.337280,64.467285 
	C583.220459,69.299164 575.103577,74.131042 566.313293,79.108101 
M1525.218994,45.975937 
	C1517.454834,48.898529 1509.690552,51.821125 1501.191040,54.874901 
	C1492.449341,59.577232 1483.707642,64.279564 1474.289551,69.125267 
	C1464.449585,76.719307 1454.609619,84.313347 1444.153198,92.141701 
	C1436.517090,100.432076 1428.880981,108.722450 1420.708862,117.451256 
	C1414.759033,126.497116 1408.809082,135.542984 1402.269653,145.013962 
	C1401.827759,145.704010 1401.330933,146.365311 1400.951660,147.088181 
	C1379.538208,187.902435 1371.533081,231.118912 1378.067505,276.828918 
	C1381.620483,301.682220 1389.286499,325.222748 1401.904907,347.804688 
	C1408.267456,357.203979 1414.629883,366.603241 1421.199341,376.644104 
	C1427.697266,383.688202 1434.195190,390.732330 1440.987183,398.307007 
	C1448.292725,404.293579 1455.598145,410.280121 1463.250244,416.826172 
	C1471.775513,422.023529 1480.300903,427.220856 1489.301636,432.878448 
	C1498.183228,436.631744 1507.064941,440.385040 1516.445679,444.655975 
	C1524.965088,447.007477 1533.484375,449.358978 1542.797852,451.959442 
	C1551.533569,453.178040 1560.269165,454.396637 1569.910645,455.828430 
	C1587.725342,456.960266 1605.450562,456.491486 1623.799561,452.921051 
	C1635.916992,449.440887 1648.034424,445.960724 1660.891602,442.281158 
	C1673.929932,435.522003 1686.968262,428.762878 1700.674561,421.842010 
	C1713.968018,412.636688 1727.124878,403.274017 1737.684814,389.899811 
	C1738.616699,389.235535 1739.717651,388.720428 1740.455078,387.884674 
	C1751.471924,375.399414 1761.692017,362.343658 1769.678345,346.888550 
	C1794.297852,301.067657 1801.106934,252.668289 1790.360352,201.843353 
	C1786.354736,182.899506 1779.961182,164.763474 1770.035278,147.244583 
	C1760.539062,129.857513 1749.254883,113.803635 1734.553955,99.479538 
	C1732.949585,98.061844 1731.227417,96.755852 1729.759399,95.208702 
	C1719.295288,84.180504 1707.206543,75.272102 1693.670288,67.143280 
	C1691.784302,66.211922 1689.914673,65.245171 1688.009399,64.355133 
	C1677.687012,59.532974 1667.355713,54.729736 1656.525146,49.406387 
	C1644.349854,46.305733 1632.174438,43.205082 1619.216309,39.836266 
	C1602.888062,37.222324 1586.510254,35.875053 1569.098999,37.816368 
	C1561.731079,38.858112 1554.363037,39.899857 1546.160400,40.883610 
	C1539.439575,42.586231 1532.718872,44.288849 1525.218994,45.975937 
M1059.767212,191.956985 
	C1054.546265,182.956802 1049.325439,173.956635 1043.987427,164.258087 
	C1037.709717,153.677261 1031.431885,143.096420 1025.044678,131.851379 
	C1022.472290,127.418571 1020.332031,122.646858 1017.199768,118.653175 
	C1014.361267,115.034103 1010.441406,112.263176 1006.339050,108.818665 
	C1001.064453,104.798386 995.256653,104.014931 988.248474,105.931702 
	C976.349915,109.846634 968.899170,119.941269 968.860840,132.616638 
	C968.809021,149.762527 968.953674,166.908951 968.991028,184.055191 
	C969.134277,249.808655 969.262024,315.562164 969.423523,381.315552 
	C969.432129,384.802521 969.758850,388.287720 969.824341,391.775848 
	C969.883728,394.936920 971.456177,396.252563 974.532898,396.173309 
	C979.354004,396.049072 984.177490,396.018829 989.921997,396.120575 
	C995.281189,396.062347 1000.640320,396.004120 1006.922058,396.120392 
	C1012.045166,396.120392 1017.168335,396.120392 1022.845398,396.120392 
	C1022.845398,393.872375 1022.848450,392.088715 1022.844971,390.305054 
	C1022.759033,346.321350 1022.674805,302.337646 1022.574951,258.353973 
	C1022.571594,256.861053 1022.311401,255.366989 1022.335144,253.876099 
	C1022.472107,245.280334 1022.658569,236.685379 1023.042908,228.756622 
	C1028.786865,238.500290 1034.530884,248.243958 1040.446655,258.636902 
	C1046.257202,268.502991 1052.067627,278.369110 1057.990845,288.916840 
	C1063.587769,298.448578 1069.184570,307.980316 1074.918091,318.174286 
	C1081.214233,328.930359 1087.510498,339.686462 1093.932129,351.104034 
	C1099.784912,361.147644 1105.637695,371.191254 1111.750977,381.893158 
	C1115.678711,388.700897 1120.337891,394.758209 1128.709351,398.093353 
	C1141.128784,402.301788 1154.605469,396.573883 1161.715454,383.899261 
	C1170.075195,369.264526 1178.434937,354.629761 1187.244751,339.460297 
	C1194.540405,326.658600 1201.836060,313.856873 1209.670410,300.652222 
	C1216.514893,288.582397 1223.359253,276.512573 1230.762329,264.007935 
	C1232.021484,261.861908 1233.264893,259.706543 1234.542236,257.571442 
	C1239.976807,248.486908 1245.421509,239.408401 1250.868408,231.224518 
	C1250.911987,233.051666 1250.991699,234.878784 1250.993774,236.705978 
	C1251.050415,287.908478 1251.099121,339.110992 1251.149048,390.313507 
	C1251.150757,392.092651 1251.149292,393.871765 1251.149292,395.948761 
	C1260.473633,395.948761 1269.236816,395.948761 1278.924438,396.117859 
	C1285.423218,396.130371 1291.922119,396.175232 1298.420776,396.145233 
	C1303.670898,396.121002 1305.542480,394.274353 1305.570068,388.901367 
	C1305.587158,385.578796 1305.194580,382.255432 1305.172363,378.931152 
	C1304.815674,325.458466 1304.492798,271.985565 1304.154419,218.512772 
	C1303.978394,190.691895 1304.137207,162.861191 1303.348755,135.057480 
	C1303.159180,128.368988 1300.656372,121.358780 1297.694092,115.233086 
	C1293.886108,107.358070 1286.215698,104.488068 1277.214844,105.965324 
	C1270.606201,106.950264 1264.716309,109.712265 1260.657104,114.932625 
	C1256.538330,120.229607 1253.297119,126.208961 1249.134155,132.284119 
	C1241.945679,144.453293 1234.757080,156.622467 1227.085327,169.236511 
	C1220.040039,181.118759 1212.994751,193.000992 1205.328735,205.207352 
	C1197.770630,218.095016 1190.212402,230.982681 1182.132568,244.279053 
	C1173.701782,258.499451 1165.270874,272.719849 1156.244995,287.278809 
	C1149.575073,298.566589 1142.905029,309.854370 1135.828247,321.830566 
	C1133.352417,317.709167 1131.373047,314.414398 1129.181519,310.528870 
	C1124.222290,302.136475 1119.263062,293.744080 1114.139404,284.705719 
	C1108.333130,274.807556 1102.526855,264.909363 1096.549438,254.368088 
	C1090.085327,243.479919 1083.621094,232.591766 1077.032104,221.026947 
	C1071.400757,211.522034 1065.769409,202.017136 1059.767212,191.956985 
z"
    />
    <path
      fill="#8E2CB2"
      opacity="1.000000"
      stroke="none"
      d="
M858.056824,369.153137 
	C849.632080,382.535492 839.297119,394.303284 827.354309,404.750153 
	C826.651428,402.019043 826.044067,399.568909 826.041443,397.118134 
	C825.986389,346.135468 826.019043,295.152740 825.944397,244.170135 
	C825.940613,241.587784 825.128357,239.006592 824.726746,236.058472 
	C825.840698,227.666550 827.763916,219.650299 827.852173,211.613876 
	C828.208862,179.134583 828.000183,146.649094 828.000183,114.165886 
	C828.000183,112.394791 828.000183,110.623695 828.000183,108.176361 
	C823.958679,108.176361 820.498901,108.176361 817.027100,107.698181 
	C817.312866,101.829498 817.610718,96.439003 817.908569,91.048508 
	C833.650085,103.509232 846.740417,118.390053 858.021423,135.775711 
	C858.055969,214.116196 858.056396,291.634674 858.056824,369.153137 
z"
    />
    <path
      fill="#912CB2"
      opacity="1.000000"
      stroke="none"
      d="
M1769.118164,347.318481 
	C1761.692017,362.343658 1751.471924,375.399414 1740.455078,387.884674 
	C1739.717651,388.720428 1738.616699,389.235535 1737.356689,389.574829 
	C1737.019165,368.184631 1737.119873,347.118225 1736.888306,326.055511 
	C1736.850830,322.643402 1735.337402,319.247559 1734.616699,315.456482 
	C1739.530640,296.694275 1742.308716,278.010223 1742.979370,259.038391 
	C1744.060303,228.457291 1741.288208,198.331543 1732.179443,168.815048 
	C1733.002319,166.388550 1733.920776,164.189392 1733.934082,161.984726 
	C1734.048950,143.087555 1733.977661,124.189331 1734.037476,105.291664 
	C1734.042969,103.550400 1734.572388,101.810791 1734.858521,100.070412 
	C1749.254883,113.803635 1760.539062,129.857513 1769.680786,147.691559 
	C1768.884155,150.115112 1768.057983,152.091293 1768.055786,154.068390 
	C1767.987671,215.705688 1767.985352,277.343079 1768.047363,338.980408 
	C1768.050049,341.760132 1768.745361,344.539154 1769.118164,347.318481 
z"
    />
    <path
      fill="#982AB1"
      opacity="1.000000"
      stroke="none"
      d="
M1277.999634,106.069069 
	C1286.215698,104.488068 1293.886108,107.358070 1297.694092,115.233086 
	C1300.656372,121.358780 1303.159180,128.368988 1303.348755,135.057480 
	C1304.137207,162.861191 1303.978394,190.691895 1304.154419,218.512772 
	C1304.492798,271.985565 1304.815674,325.458466 1305.172363,378.931152 
	C1305.194580,382.255432 1305.587158,385.578796 1305.570068,388.901367 
	C1305.542480,394.274353 1303.670898,396.121002 1298.420776,396.145233 
	C1291.922119,396.175232 1285.423218,396.130371 1278.462158,395.568298 
	C1277.999878,298.702179 1277.999756,202.385620 1277.999634,106.069069 
z"
    />
    <path
      fill="#8F2CB2"
      opacity="1.000000"
      stroke="none"
      d="
M1277.607178,106.017197 
	C1277.999756,202.385620 1277.999878,298.702179 1278.000000,395.483765 
	C1269.236816,395.948761 1260.473633,395.948761 1251.149292,395.948761 
	C1251.149292,393.871765 1251.150757,392.092651 1251.149048,390.313507 
	C1251.099121,339.110992 1251.050415,287.908478 1250.993774,236.705978 
	C1250.991699,234.878784 1250.911987,233.051666 1251.035522,230.366760 
	C1251.466675,228.394180 1251.959839,227.279694 1251.961426,226.164505 
	C1252.004150,195.953110 1252.023682,165.741547 1251.899048,135.530624 
	C1251.894043,134.313843 1250.446777,133.103043 1249.671509,131.889465 
	C1253.297119,126.208961 1256.538330,120.229607 1260.657104,114.932625 
	C1264.716309,109.712265 1270.606201,106.950264 1277.607178,106.017197 
z"
    />
    <path
      fill="#6939B7"
      opacity="1.000000"
      stroke="none"
      d="
M673.000732,43.145290 
	C684.368896,43.408905 695.737122,43.672520 707.552612,44.499382 
	C708.000488,113.094406 708.001099,181.126190 707.541260,249.256744 
	C695.720581,249.290192 684.360291,249.224838 673.000000,248.680847 
	C673.000244,179.849884 673.000488,111.497589 673.000732,43.145290 
z"
    />
    <path
      fill="#7934B5"
      opacity="1.000000"
      stroke="none"
      d="
M738.997742,50.089935 
	C751.552856,54.762260 764.107910,59.434593 776.833008,64.779190 
	C776.668762,67.729088 776.066895,70.004135 776.044800,72.284805 
	C775.927063,84.419800 775.954468,96.556206 775.895020,109.183319 
	C775.852844,143.156372 775.904541,176.638382 775.821289,210.120056 
	C775.767639,231.692780 758.069214,249.861374 738.001099,248.682831 
	C738.000183,186.780701 737.978638,125.357330 738.043030,63.934059 
	C738.047852,59.319027 738.665161,54.704632 738.997742,50.089935 
z"
    />
    <path
      fill="#7037B6"
      opacity="1.000000"
      stroke="none"
      d="
M738.655701,49.915550 
	C738.665161,54.704632 738.047852,59.319027 738.043030,63.934059 
	C737.978638,125.357330 738.000183,186.780701 737.540405,248.779907 
	C727.387512,249.289841 717.694641,249.223892 708.001709,249.157959 
	C708.001099,181.126190 708.000488,113.094406 707.999146,44.603760 
	C718.103455,46.010315 728.208557,47.875740 738.655701,49.915550 
z"
    />
    <path
      fill="#962AB1"
      opacity="1.000000"
      stroke="none"
      d="
M858.342285,368.962708 
	C858.056396,291.634674 858.055969,214.116196 858.094116,136.099091 
	C871.922058,154.878281 881.375366,176.206940 887.127319,199.085785 
	C902.377869,259.745789 892.735596,316.343109 858.342285,368.962708 
z"
    />
    <path
      fill="#990DFA"
      opacity="1.000000"
      stroke="none"
      d="
M989.000000,395.948792 
	C984.177490,396.018829 979.354004,396.049072 974.532898,396.173309 
	C971.456177,396.252563 969.883728,394.936920 969.824341,391.775848 
	C969.758850,388.287720 969.432129,384.802521 969.423523,381.315552 
	C969.262024,315.562164 969.134277,249.808655 968.991028,184.055191 
	C968.953674,166.908951 968.809021,149.762527 968.860840,132.616638 
	C968.899170,119.941269 976.349915,109.846634 988.624268,106.418243 
	C989.000000,203.252792 989.000000,299.600800 989.000000,395.948792 
z"
    />
    <path
      fill="#8D17EA"
      opacity="1.000000"
      stroke="none"
      d="
M1421.244873,117.012840 
	C1428.880981,108.722450 1436.517090,100.432076 1444.874634,92.121994 
	C1446.064087,93.895317 1446.930786,95.686508 1446.938965,97.481667 
	C1447.034302,118.279404 1446.969482,139.077850 1447.046631,159.875763 
	C1447.054443,161.974396 1447.713013,164.070633 1448.036499,166.531158 
	C1445.314331,175.608139 1441.540527,184.149399 1440.121704,193.065369 
	C1433.635620,233.827011 1434.682129,274.510376 1442.868652,315.199463 
	C1442.033081,320.175568 1441.119995,324.898560 1441.067383,329.631134 
	C1440.815063,352.344818 1440.795410,375.061096 1440.693115,397.776459 
	C1434.195190,390.732330 1427.697266,383.688202 1421.492920,376.232300 
	C1422.191040,374.321930 1422.901855,372.832001 1422.944336,371.323242 
	C1423.089600,366.165894 1423.000000,361.001953 1423.000000,355.840515 
	C1423.000000,278.086090 1423.012085,200.331665 1422.926636,122.577370 
	C1422.924561,120.721916 1421.829834,118.867668 1421.244873,117.012840 
z"
    />
    <path
      fill="#9211F2"
      opacity="1.000000"
      stroke="none"
      d="
M989.460999,396.034668 
	C989.000000,299.600800 989.000000,203.252792 988.999512,106.433411 
	C995.256653,104.014931 1001.064453,104.798386 1006.668945,109.395020 
	C1006.665955,121.078224 1006.054932,132.184799 1006.044250,143.291962 
	C1005.963196,227.509872 1005.999695,311.727905 1005.999512,395.945892 
	C1000.640320,396.004120 995.281189,396.062347 989.460999,396.034668 
z"
    />
    <path
      fill="#8E16EB"
      opacity="1.000000"
      stroke="none"
      d="
M1006.460815,396.033142 
	C1005.999695,311.727905 1005.963196,227.509872 1006.044250,143.291962 
	C1006.054932,132.184799 1006.665955,121.078224 1006.998657,109.544823 
	C1010.441406,112.263176 1014.361267,115.034103 1017.199768,118.653175 
	C1020.332031,122.646858 1022.472290,127.418571 1025.024902,132.635803 
	C1024.670044,135.863129 1024.049805,138.305298 1024.043579,140.749023 
	C1023.974304,168.001022 1024.023071,195.253296 1023.949707,222.505249 
	C1023.944702,224.367874 1023.218811,226.228561 1022.828247,228.090149 
	C1022.658569,236.685379 1022.472107,245.280334 1022.335144,253.876099 
	C1022.311401,255.366989 1022.571594,256.861053 1022.574951,258.353973 
	C1022.674805,302.337646 1022.759033,346.321350 1022.844971,390.305054 
	C1022.848450,392.088715 1022.845398,393.872375 1022.845398,396.120392 
	C1017.168335,396.120392 1012.045166,396.120392 1006.460815,396.033142 
z"
    />
    <path
      fill="#7236B6"
      opacity="1.000000"
      stroke="none"
      d="
M740.999390,453.925537 
	C730.954956,456.009827 720.910522,458.094086 710.433167,459.657898 
	C710.000793,438.131958 710.001404,417.126434 710.319885,395.830688 
	C710.637817,364.152100 710.637817,332.763763 710.637817,301.392303 
	C721.695679,301.392303 731.346924,301.392303 740.998535,301.836365 
	C740.999084,352.828827 740.999268,403.377167 740.999390,453.925537 
z"
    />
    <path
      fill="#862FB3"
      opacity="1.000000"
      stroke="none"
      d="
M824.692566,236.424866 
	C825.128357,239.006592 825.940613,241.587784 825.944397,244.170135 
	C826.019043,295.152740 825.986389,346.135468 826.041443,397.118134 
	C826.044067,399.568909 826.651428,402.019043 827.049866,404.909760 
	C817.297485,413.118317 807.472412,420.886627 797.322693,428.315216 
	C796.665405,418.694733 796.072266,409.414764 796.045532,400.133118 
	C795.936340,362.165466 796.006592,324.197357 795.983948,286.229401 
	C795.982544,283.906860 795.749451,281.584473 795.811646,279.014374 
	C798.378235,276.464386 800.823547,274.226166 803.124878,271.848572 
	C812.977722,261.669373 819.581665,249.498459 824.692566,236.424866 
z"
    />
    <path
      fill="#8A19E7"
      opacity="1.000000"
      stroke="none"
      d="
M550.063965,412.807831 
	C544.074219,407.263611 538.084534,401.719391 532.008911,395.377014 
	C531.948242,393.579956 531.995483,392.581055 531.995544,391.582153 
	C531.999268,299.137360 532.000793,206.692566 531.988586,114.247780 
	C531.988403,112.587082 531.821350,110.926399 531.732239,109.265701 
	C538.908752,102.475960 546.085205,95.686218 553.612915,89.252914 
	C553.961853,95.808861 553.959595,102.008369 553.501587,108.290131 
	C549.098694,108.372391 545.151489,108.372391 541.158691,108.372391 
	C541.010010,109.861221 540.857300,110.678741 540.857056,111.496300 
	C540.844666,146.295013 540.835815,181.093735 540.871399,215.892395 
	C540.873474,217.875305 540.910400,219.968933 541.512390,221.822220 
	C545.038696,232.678864 548.738159,243.479294 552.257874,254.750427 
	C551.757690,257.809875 551.052063,260.417084 551.048462,263.025269 
	C550.982178,310.846375 551.011902,358.667633 550.960388,406.488800 
	C550.958130,408.595459 550.375977,410.701508 550.063965,412.807831 
z"
    />
    <path
      fill="#8E15EC"
      opacity="1.000000"
      stroke="none"
      d="
M531.419922,109.435074 
	C531.821350,110.926399 531.988403,112.587082 531.988586,114.247780 
	C532.000793,206.692566 531.999268,299.137360 531.995544,391.582153 
	C531.995483,392.581055 531.948242,393.579956 531.877380,395.092682 
	C525.958069,388.594391 520.084412,381.582275 514.292603,373.861389 
	C514.582886,371.358398 514.973145,369.564240 514.973877,367.769958 
	C515.004272,290.897247 515.008484,214.024521 514.955383,137.151840 
	C514.953918,135.054749 514.289368,132.958115 513.934021,130.861267 
	C519.658569,123.775665 525.383118,116.690056 531.419922,109.435074 
z"
    />
    <path
      fill="#9112F0"
      opacity="1.000000"
      stroke="none"
      d="
M1420.976807,117.232056 
	C1421.829834,118.867668 1422.924561,120.721916 1422.926636,122.577370 
	C1423.012085,200.331665 1423.000000,278.086090 1423.000000,355.840515 
	C1423.000000,361.001953 1423.089600,366.165894 1422.944336,371.323242 
	C1422.901855,372.832001 1422.191040,374.321930 1421.389404,375.911499 
	C1414.629883,366.603241 1408.267456,357.203979 1402.203857,347.265076 
	C1403.001953,344.563568 1403.934204,342.402252 1403.936890,340.239716 
	C1404.014893,277.800049 1404.014648,215.360229 1403.951660,152.920502 
	C1403.948853,150.142914 1403.239502,147.366043 1402.859253,144.588837 
	C1408.809082,135.542984 1414.759033,126.497116 1420.976807,117.232056 
z"
    />
    <path
      fill="#7131C4"
      opacity="1.000000"
      stroke="none"
      d="
M633.003723,49.190662 
	C640.052979,47.764530 647.102234,46.338398 654.575745,45.422394 
	C654.999878,113.674843 654.999756,181.417175 654.539673,249.259476 
	C647.386414,249.268860 640.693237,249.178268 634.000000,248.613525 
	C634.000000,184.434921 634.011780,120.730469 633.957886,57.026066 
	C633.955688,54.413998 633.335815,51.802452 633.003723,49.190662 
z"
    />
    <path
      fill="#7F32B4"
      opacity="1.000000"
      stroke="none"
      d="
M795.624207,279.261963 
	C795.749451,281.584473 795.982544,283.906860 795.983948,286.229401 
	C796.006592,324.197357 795.936340,362.165466 796.045532,400.133118 
	C796.072266,409.414764 796.665405,418.694733 796.998169,428.397339 
	C787.598389,433.919678 778.198425,439.020203 768.411743,443.685333 
	C768.018005,394.244385 768.011047,345.238800 768.313110,296.040466 
	C777.622742,290.319153 786.623474,284.790558 795.624207,279.261963 
z"
    />
    <path
      fill="#762CCB"
      opacity="1.000000"
      stroke="none"
      d="
M632.620972,49.178490 
	C633.335815,51.802452 633.955688,54.413998 633.957886,57.026066 
	C634.011780,120.730469 634.000000,184.434921 633.573425,248.659821 
	C625.409241,248.628815 618.289062,246.337509 612.080017,241.285675 
	C612.433838,238.940735 612.962769,236.988785 612.964294,235.036438 
	C613.008362,177.411728 613.011230,119.786980 612.955261,62.162285 
	C612.953247,60.062607 612.292419,57.963562 611.938599,55.864223 
	C618.705139,53.631592 625.471741,51.398952 632.620972,49.178490 
z"
    />
    <path
      fill="#7934B5"
      opacity="1.000000"
      stroke="none"
      d="
M768.004089,296.233215 
	C768.011047,345.238800 768.018005,394.244385 768.043640,443.734375 
	C759.299866,447.466095 750.537415,450.713440 741.387207,453.943146 
	C740.999268,403.377167 740.999084,352.828827 741.456543,301.742584 
	C751.114868,302.192871 759.658386,299.732117 768.004089,296.233215 
z"
    />
    <path
      fill="#960FF7"
      opacity="1.000000"
      stroke="none"
      d="
M1402.564453,144.801392 
	C1403.239502,147.366043 1403.948853,150.142914 1403.951660,152.920502 
	C1404.014648,215.360229 1404.014893,277.800049 1403.936890,340.239716 
	C1403.934204,342.402252 1403.001953,344.563568 1402.133545,346.914490 
	C1389.286499,325.222748 1381.620483,301.682220 1378.067505,276.828918 
	C1371.533081,231.118912 1379.538208,187.902435 1400.951660,147.088181 
	C1401.330933,146.365311 1401.827759,145.704010 1402.564453,144.801392 
z"
    />
    <path
      fill="#9211F2"
      opacity="1.000000"
      stroke="none"
      d="
M513.639282,131.039886 
	C514.289368,132.958115 514.953918,135.054749 514.955383,137.151840 
	C515.008484,214.024521 515.004272,290.897247 514.973877,367.769958 
	C514.973145,369.564240 514.582886,371.358398 514.210083,373.529419 
	C507.992065,363.882965 501.938263,353.859711 496.151428,343.208557 
	C496.612122,341.453979 496.974854,340.327454 496.975403,339.200714 
	C497.002930,281.539581 497.010651,223.878448 496.937866,166.217407 
	C496.936005,164.738480 496.015045,163.260696 495.522705,161.782394 
	C501.463287,151.594437 507.403900,141.406464 513.639282,131.039886 
z"
    />
    <path
      fill="#982AB1"
      opacity="1.000000"
      stroke="none"
      d="
M1769.398193,347.103516 
	C1768.745361,344.539154 1768.050049,341.760132 1768.047363,338.980408 
	C1767.985352,277.343079 1767.987671,215.705688 1768.055786,154.068390 
	C1768.057983,152.091293 1768.884155,150.115112 1769.733643,148.048126 
	C1779.961182,164.763474 1786.354736,182.899506 1790.360352,201.843353 
	C1801.106934,252.668289 1794.297852,301.067657 1769.398193,347.103516 
z"
    />
    <path
      fill="#7032C3"
      opacity="1.000000"
      stroke="none"
      d="
M662.999146,460.764709 
	C654.218445,459.306610 645.437683,457.848511 636.328491,455.719269 
	C636.000000,404.384949 636.000000,353.721741 636.462646,302.975830 
	C643.973938,302.893097 651.022583,302.893097 658.199646,302.893097 
	C658.199646,334.604614 658.199646,365.215179 658.199646,395.838379 
	C660.027222,395.963562 661.513916,396.065369 663.000244,396.625366 
	C662.999634,418.310577 662.999390,439.537659 662.999146,460.764709 
z"
    />
    <path
      fill="#6C35BE"
      opacity="1.000000"
      stroke="none"
      d="
M654.999634,249.159500 
	C654.999756,181.417175 654.999878,113.674843 655.000000,45.463459 
	C660.707886,44.337784 666.415710,43.681171 672.562134,43.084923 
	C673.000488,111.497589 673.000244,179.849884 672.540649,248.780991 
	C666.387390,249.293030 660.693542,249.226257 654.999634,249.159500 
z"
    />
    <path
      fill="#7934B5"
      opacity="1.000000"
      stroke="none"
      d="
M258.999451,109.053696 
	C267.691406,108.997528 276.383392,108.941353 285.537659,109.434326 
	C286.042175,150.105240 286.084381,190.227005 286.126556,230.348770 
	C277.707214,239.838943 269.287872,249.329117 260.243286,258.718323 
	C259.412048,256.830750 259.031769,255.044525 259.027039,253.257324 
	C258.981812,236.117691 259.096252,218.977112 258.954620,201.838531 
	C258.894470,194.560226 258.335754,187.286072 258.003601,180.010025 
	C263.111969,173.953445 268.220367,167.896866 274.058716,160.974792 
	C268.876373,160.974792 264.398102,160.974792 259.459839,160.447250 
	C258.999725,142.964371 258.999573,126.009033 258.999451,109.053696 
z"
    />
    <path
      fill="#861EE0"
      opacity="1.000000"
      stroke="none"
      d="
M550.242920,413.094177 
	C550.375977,410.701508 550.958130,408.595459 550.960388,406.488800 
	C551.011902,358.667633 550.982178,310.846375 551.048462,263.025269 
	C551.052063,260.417084 551.757690,257.809875 552.497925,255.018219 
	C558.592590,264.987396 566.428894,273.345795 574.824341,281.176819 
	C574.000366,283.782806 573.070251,286.161377 573.063477,288.542603 
	C572.929871,335.526886 572.903442,382.511475 572.855103,429.496002 
	C565.377380,424.124176 557.899597,418.752350 550.242920,413.094177 
z"
    />
    <path
      fill="#6B39B8"
      opacity="1.000000"
      stroke="none"
      d="
M1622.998291,452.855835 
	C1605.450562,456.491486 1587.725342,456.960266 1569.455566,455.279419 
	C1568.999878,434.215942 1568.999390,413.701508 1569.466064,393.148560 
	C1587.622070,393.093018 1605.310913,393.075989 1622.999756,393.523926 
	C1622.999512,413.611206 1622.998901,433.233521 1622.998291,452.855835 
z"
    />
    <path
      fill="#762CCB"
      opacity="1.000000"
      stroke="none"
      d="
M636.000000,303.058533 
	C636.000000,353.721741 636.000000,404.384949 636.000000,455.515625 
	C628.811340,453.961853 621.622620,451.940613 614.178955,449.236145 
	C613.948792,399.065826 613.973694,349.578735 614.394775,300.071106 
	C621.860657,301.053223 628.930298,302.055878 636.000000,303.058533 
z"
    />
    <path
      fill="#7B28D2"
      opacity="1.000000"
      stroke="none"
      d="
M611.561157,55.887169 
	C612.292419,57.963562 612.953247,60.062607 612.955261,62.162285 
	C613.011230,119.786980 613.008362,177.411728 612.964294,235.036438 
	C612.962769,236.988785 612.433838,238.940735 611.777954,241.179474 
	C600.636108,233.092636 594.316406,223.202499 594.728516,208.437820 
	C595.615234,176.666122 595.002747,144.852539 594.996887,113.056099 
	C594.996460,110.830711 595.717041,108.128410 592.000610,107.682426 
	C592.001648,92.903038 592.003235,78.602417 592.004761,64.301788 
	C598.397766,61.504562 604.790771,58.707340 611.561157,55.887169 
z"
    />
    <path
      fill="#6B39B8"
      opacity="1.000000"
      stroke="none"
      d="
M257.703918,180.156525 
	C258.335754,187.286072 258.894470,194.560226 258.954620,201.838531 
	C259.096252,218.977112 258.981812,236.117691 259.027039,253.257324 
	C259.031769,255.044525 259.412048,256.830750 259.964386,258.889069 
	C247.059525,273.972198 233.808350,288.783661 220.215454,303.340515 
	C219.582504,300.968750 219.044235,298.852356 219.038132,296.734436 
	C218.975800,275.113007 219.042694,253.491089 218.914185,231.870255 
	C218.904510,230.243530 217.673981,228.624069 217.010971,227.001221 
	C230.475372,211.435165 243.939774,195.869095 257.703918,180.156525 
z"
    />
    <path
      fill="#8023D8"
      opacity="1.000000"
      stroke="none"
      d="
M573.069824,429.733459 
	C572.903442,382.511475 572.929871,335.526886 573.063477,288.542603 
	C573.070251,286.161377 574.000366,283.782806 575.142700,281.279816 
	C581.904602,284.954102 588.024353,288.751526 594.103333,293.044373 
	C594.034363,342.720551 594.006165,391.901337 593.977905,441.082092 
	C587.080139,437.378357 580.182312,433.674652 573.069824,429.733459 
z"
    />
    <path
      fill="#6B39B8"
      opacity="1.000000"
      stroke="none"
      d="
M1570.000000,37.907280 
	C1586.510254,35.875053 1602.888062,37.222324 1619.608154,40.431820 
	C1620.000000,60.665905 1620.000000,80.304436 1619.537598,100.026932 
	C1602.716553,100.055466 1586.357788,100.000023 1569.999634,99.479401 
	C1570.000000,78.645233 1570.000000,58.276257 1570.000000,37.907280 
z"
    />
    <path
      fill="#6A38B8"
      opacity="1.000000"
      stroke="none"
      d="
M663.441650,460.865112 
	C662.999390,439.537659 662.999634,418.310577 663.464355,396.556458 
	C679.286560,396.059875 694.644287,396.090424 710.001953,396.120941 
	C710.001404,417.126434 710.000793,438.131958 710.000977,459.612793 
	C697.276062,460.600250 684.552307,461.166504 671.823486,461.580292 
	C669.186523,461.665955 666.531128,461.185181 663.441650,460.865112 
z"
    />
    <path
      fill="#7B28D2"
      opacity="1.000000"
      stroke="none"
      d="
M594.208008,441.338135 
	C594.006165,391.901337 594.034363,342.720551 594.459839,293.161377 
	C601.237671,295.219177 607.618103,297.655426 613.998596,300.091675 
	C613.973694,349.578735 613.948792,399.065826 613.877441,449.062958 
	C607.366638,446.913391 600.902344,444.253754 594.208008,441.338135 
z"
    />
    <path
      fill="#6B39B8"
      opacity="1.000000"
      stroke="none"
      d="
M1161.145020,384.307434 
	C1154.605469,396.573883 1141.128784,402.301788 1128.354736,397.462494 
	C1128.000000,371.522888 1127.949829,346.213898 1128.061523,320.905640 
	C1128.075928,317.641724 1128.928955,314.381531 1129.393555,311.119629 
	C1131.373047,314.414398 1133.352417,317.709167 1135.828247,321.830566 
	C1142.905029,309.854370 1149.575073,298.566589 1156.968506,287.140228 
	C1158.461426,288.698517 1159.893066,290.392181 1159.901367,292.092712 
	C1160.039551,320.570251 1159.974121,349.048706 1160.049683,377.526703 
	C1160.055664,379.787872 1160.763428,382.047211 1161.145020,384.307434 
z"
    />
    <path
      fill="#960FF7"
      opacity="1.000000"
      stroke="none"
      d="
M495.311096,162.042740 
	C496.015045,163.260696 496.936005,164.738480 496.937866,166.217407 
	C497.010651,223.878448 497.002930,281.539581 496.975403,339.200714 
	C496.974854,340.327454 496.612122,341.453979 496.078644,342.853973 
	C487.266846,327.187256 481.971741,310.126373 478.634369,292.497742 
	C470.090515,247.367020 475.383087,203.901871 495.311096,162.042740 
z"
    />
    <path
      fill="#8231B4"
      opacity="1.000000"
      stroke="none"
      d="
M286.431854,230.187225 
	C286.084381,190.227005 286.042175,150.105240 285.999908,109.518616 
	C294.358551,108.998230 302.717255,108.942703 311.538177,109.435776 
	C312.000275,138.056900 311.972351,166.129532 312.058990,194.201782 
	C312.065155,196.195496 312.912994,198.186630 313.369476,200.178955 
	C304.492035,210.127853 295.614594,220.076767 286.431854,230.187225 
z"
    />
    <path
      fill="#7236B6"
      opacity="1.000000"
      stroke="none"
      d="
M1161.430176,384.103333 
	C1160.763428,382.047211 1160.055664,379.787872 1160.049683,377.526703 
	C1159.974121,349.048706 1160.039551,320.570251 1159.901367,292.092712 
	C1159.893066,290.392181 1158.461426,288.698517 1157.266113,286.970947 
	C1165.270874,272.719849 1173.701782,258.499451 1182.815674,244.173279 
	C1183.999146,245.834900 1184.930054,247.600906 1184.935669,249.369888 
	C1185.026611,277.906616 1184.969849,306.443817 1185.076782,334.980438 
	C1185.083008,336.654053 1186.196411,338.323578 1186.794678,339.994995 
	C1178.434937,354.629761 1170.075195,369.264526 1161.430176,384.103333 
z"
    />
    <path
      fill="#882FB3"
      opacity="1.000000"
      stroke="none"
      d="
M1734.506104,315.844177 
	C1735.337402,319.247559 1736.850830,322.643402 1736.888306,326.055511 
	C1737.119873,347.118225 1737.019165,368.184631 1737.050293,389.733429 
	C1727.124878,403.274017 1713.968018,412.636688 1700.036865,421.683289 
	C1698.932739,419.312561 1698.079224,417.103577 1698.062378,414.888153 
	C1697.946045,399.663605 1698.004517,384.437714 1698.231201,368.953491 
	C1715.692627,354.638580 1727.603027,336.949127 1734.506104,315.844177 
z"
    />
    <path
      fill="#862FB3"
      opacity="1.000000"
      stroke="none"
      d="
M1734.706299,99.774979 
	C1734.572388,101.810791 1734.042969,103.550400 1734.037476,105.291664 
	C1733.977661,124.189331 1734.048950,143.087555 1733.934082,161.984726 
	C1733.920776,164.189392 1733.002319,166.388550 1731.922852,168.578583 
	C1726.572998,157.451553 1721.229614,146.596146 1712.608643,137.940094 
	C1706.335815,131.641830 1699.356812,126.046936 1693.036255,119.870026 
	C1693.586670,117.827698 1693.964111,116.054695 1693.973267,114.279808 
	C1694.052490,98.745377 1694.066040,83.210602 1694.099121,67.675934 
	C1707.206543,75.272102 1719.295288,84.180504 1729.759399,95.208702 
	C1731.227417,96.755852 1732.949585,98.061844 1734.706299,99.774979 
z"
    />
    <path
      fill="#882FB3"
      opacity="1.000000"
      stroke="none"
      d="
M1249.402832,132.086792 
	C1250.446777,133.103043 1251.894043,134.313843 1251.899048,135.530624 
	C1252.023682,165.741547 1252.004150,195.953110 1251.961426,226.164505 
	C1251.959839,227.279694 1251.466675,228.394180 1251.032471,229.918335 
	C1245.421509,239.408401 1239.976807,248.486908 1234.542236,257.571442 
	C1233.264893,259.706543 1232.021484,261.861908 1230.390625,263.779907 
	C1229.679321,261.104523 1229.050537,258.657928 1229.044189,256.209686 
	C1228.973877,229.057556 1229.030029,201.905060 1228.938354,174.753067 
	C1228.931641,172.764404 1228.045776,170.778702 1227.568604,168.791641 
	C1234.757080,156.622467 1241.945679,144.453293 1249.402832,132.086792 
z"
    />
    <path
      fill="#7934B5"
      opacity="1.000000"
      stroke="none"
      d="
M1187.019775,339.727661 
	C1186.196411,338.323578 1185.083008,336.654053 1185.076782,334.980438 
	C1184.969849,306.443817 1185.026611,277.906616 1184.935669,249.369888 
	C1184.930054,247.600906 1183.999146,245.834900 1183.076416,243.968933 
	C1190.212402,230.982681 1197.770630,218.095016 1205.807495,205.437531 
	C1206.524170,207.619553 1206.966064,209.571075 1206.969238,211.523331 
	C1207.014526,238.936508 1206.946289,266.350067 1207.092651,293.762573 
	C1207.105591,296.196991 1208.421143,298.624451 1209.131714,301.055176 
	C1201.836060,313.856873 1194.540405,326.658600 1187.019775,339.727661 
z"
    />
    <path
      fill="#8231B4"
      opacity="1.000000"
      stroke="none"
      d="
M1209.401123,300.853699 
	C1208.421143,298.624451 1207.105591,296.196991 1207.092651,293.762573 
	C1206.946289,266.350067 1207.014526,238.936508 1206.969238,211.523331 
	C1206.966064,209.571075 1206.524170,207.619553 1206.117920,205.275467 
	C1212.994751,193.000992 1220.040039,181.118759 1227.326904,169.014069 
	C1228.045776,170.778702 1228.931641,172.764404 1228.938354,174.753067 
	C1229.030029,201.905060 1228.973877,229.057556 1229.044189,256.209686 
	C1229.050537,258.657928 1229.679321,261.104523 1230.111328,263.997314 
	C1223.359253,276.512573 1216.514893,288.582397 1209.401123,300.853699 
z"
    />
    <path
      fill="#7E24D7"
      opacity="1.000000"
      stroke="none"
      d="
M162.995346,288.985657 
	C168.782303,282.061035 174.569275,275.136383 180.847198,268.493286 
	C181.558777,270.397003 181.969070,272.018860 181.971634,273.641388 
	C182.012054,299.296478 182.000198,324.951691 182.000183,350.606842 
	C182.000168,365.386810 181.999893,380.166779 181.999741,394.946747 
	C175.974640,395.003937 169.949524,395.061127 163.462616,394.567932 
	C162.998978,359.006897 162.997162,323.996277 162.995346,288.985657 
z"
    />
    <path
      fill="#782CCD"
      opacity="1.000000"
      stroke="none"
      d="
M182.456375,395.032501 
	C181.999893,380.166779 182.000168,365.386810 182.000183,350.606842 
	C182.000198,324.951691 182.012054,299.296478 181.971634,273.641388 
	C181.969070,272.018860 181.558777,270.397003 181.171173,268.392151 
	C187.102097,261.012299 193.200012,254.015106 199.630920,247.283386 
	C200.309082,250.833786 200.937881,254.117447 200.952988,257.403931 
	C201.045685,277.557800 200.958755,297.712555 201.072662,317.866180 
	C201.082336,319.578186 202.123825,321.284302 202.685974,322.993164 
	C196.610184,329.982239 190.534378,336.971313 183.656708,344.882782 
	C187.721619,344.882782 190.916260,344.882782 194.555450,345.432739 
	C195.000076,362.304199 195.000168,378.625671 195.000244,394.947144 
	C190.971161,395.004181 186.942093,395.061218 182.456375,395.032501 
z"
    />
    <path
      fill="#7436B6"
      opacity="1.000000"
      stroke="none"
      d="
M1619.999878,99.942963 
	C1620.000000,80.304436 1620.000000,60.665905 1619.999512,40.565903 
	C1632.174438,43.205082 1644.349854,46.305733 1656.767822,50.137291 
	C1657.006958,66.412079 1657.093628,81.957169 1656.886353,97.498360 
	C1656.860474,99.448265 1655.284790,101.377510 1654.046387,103.299164 
	C1642.443604,102.168983 1631.221802,101.055969 1619.999878,99.942963 
z"
    />
    <path
      fill="#7436B6"
      opacity="1.000000"
      stroke="none"
      d="
M1623.398926,452.888428 
	C1622.998901,433.233521 1622.999512,413.611206 1623.458618,393.438599 
	C1635.920410,391.502258 1647.923706,390.116211 1659.948486,389.215302 
	C1660.030762,407.293793 1660.091309,424.887177 1660.151855,442.480530 
	C1648.034424,445.960724 1635.916992,449.440887 1623.398926,452.888428 
z"
    />
    <path
      fill="#7F32B4"
      opacity="1.000000"
      stroke="none"
      d="
M1660.521729,442.380859 
	C1660.091309,424.887177 1660.030762,407.293793 1660.215698,388.981659 
	C1674.253052,384.428619 1686.514648,377.578766 1698.006714,369.212250 
	C1698.004517,384.437714 1697.946045,399.663605 1698.062378,414.888153 
	C1698.079224,417.103577 1698.932739,419.312561 1699.702881,421.764130 
	C1686.968262,428.762878 1673.929932,435.522003 1660.521729,442.380859 
z"
    />
    <path
      fill="#861EE0"
      opacity="1.000000"
      stroke="none"
      d="
M1448.069580,166.167969 
	C1447.713013,164.070633 1447.054443,161.974396 1447.046631,159.875763 
	C1446.969482,139.077850 1447.034302,118.279404 1446.938965,97.481667 
	C1446.930786,95.686508 1446.064087,93.895317 1445.182861,92.004837 
	C1454.609619,84.313347 1464.449585,76.719307 1474.977051,69.248787 
	C1476.109619,71.220680 1476.929565,73.066559 1476.941895,74.917809 
	C1477.057861,92.334633 1477.036133,109.752388 1476.913208,127.465195 
	C1464.378296,138.450806 1455.346313,151.653534 1448.069580,166.167969 
z"
    />
    <path
      fill="#7B34B5"
      opacity="1.000000"
      stroke="none"
      d="
M1654.427246,103.316338 
	C1655.284790,101.377510 1656.860474,99.448265 1656.886353,97.498360 
	C1657.093628,81.957169 1657.006958,66.412079 1657.019043,50.394093 
	C1667.355713,54.729736 1677.687012,59.532974 1688.009399,64.355133 
	C1689.914673,65.245171 1691.784302,66.211922 1693.884766,67.409607 
	C1694.066040,83.210602 1694.052490,98.745377 1693.973267,114.279808 
	C1693.964111,116.054695 1693.586670,117.827698 1692.705933,119.801636 
	C1679.497070,114.439903 1666.962158,108.878120 1654.427246,103.316338 
z"
    />
    <path
      fill="#8E2CB2"
      opacity="1.000000"
      stroke="none"
      d="
M313.652557,200.021698 
	C312.912994,198.186630 312.065155,196.195496 312.058990,194.201782 
	C311.972351,166.129532 312.000275,138.056900 312.000549,109.519592 
	C320.027039,109.000587 328.053345,108.946358 336.539825,109.452072 
	C337.000000,128.894318 336.969727,147.776733 337.042633,166.658737 
	C337.050720,168.753159 337.647156,170.845291 337.970734,172.938492 
	C329.959045,181.913803 321.947327,190.889130 313.652557,200.021698 
z"
    />
    <path
      fill="#6B39B8"
      opacity="1.000000"
      stroke="none"
      d="
M258.537170,108.969559 
	C258.999573,126.009033 258.999725,142.964371 258.999298,160.377304 
	C246.974716,160.881592 234.950699,160.928268 222.462830,160.447632 
	C221.998642,142.964340 221.998276,126.008392 221.997910,109.052437 
	C234.023575,108.996758 246.049225,108.941086 258.537170,108.969559 
z"
    />
    <path
      fill="#782CCD"
      opacity="1.000000"
      stroke="none"
      d="
M1077.156982,221.703613 
	C1083.621094,232.591766 1090.085327,243.479919 1096.262939,254.807114 
	C1095.651001,257.121368 1095.067139,258.992676 1095.038574,260.872375 
	C1094.586060,290.728485 1094.204468,320.585632 1093.806641,350.442566 
	C1087.510498,339.686462 1081.214233,328.930359 1075.023926,317.416077 
	C1075.419922,314.546997 1075.958008,312.436707 1075.962402,310.325256 
	C1076.019165,283.040588 1075.972778,255.755707 1076.050171,228.471146 
	C1076.056519,226.214279 1076.771484,223.959427 1077.156982,221.703613 
z"
    />
    <path
      fill="#7131C4"
      opacity="1.000000"
      stroke="none"
      d="
M1093.869385,350.773315 
	C1094.204468,320.585632 1094.586060,290.728485 1095.038574,260.872375 
	C1095.067139,258.992676 1095.651001,257.121368 1096.348633,255.128662 
	C1102.526855,264.909363 1108.333130,274.807556 1114.032959,285.465149 
	C1113.617554,288.329926 1113.044556,290.434692 1113.040039,292.540741 
	C1112.979858,320.137787 1113.030640,347.735046 1112.934937,375.331909 
	C1112.928101,377.301208 1111.993652,379.267273 1111.490479,381.234863 
	C1105.637695,371.191254 1099.784912,361.147644 1093.869385,350.773315 
z"
    />
    <path
      fill="#861EE0"
      opacity="1.000000"
      stroke="none"
      d="
M162.685532,289.123230 
	C162.997162,323.996277 162.998978,359.006897 163.001007,394.482483 
	C156.975540,395.005646 150.949844,395.063812 144.462677,394.570557 
	C144.001205,377.555908 144.042557,361.092499 143.986801,344.629486 
	C143.949097,333.495697 143.774704,322.362366 143.661469,311.228851 
	C149.899551,303.906189 156.137634,296.583527 162.685532,289.123230 
z"
    />
    <path
      fill="#881CE3"
      opacity="1.000000"
      stroke="none"
      d="
M1022.935547,228.423370 
	C1023.218811,226.228561 1023.944702,224.367874 1023.949707,222.505249 
	C1024.023071,195.253296 1023.974304,168.001022 1024.043579,140.749023 
	C1024.049805,138.305298 1024.670044,135.863129 1025.079590,132.967896 
	C1031.431885,143.096420 1037.709717,153.677261 1043.689209,164.798859 
	C1042.927368,167.343460 1042.066650,169.345978 1042.060059,171.351288 
	C1041.970703,198.541611 1042.030762,225.732422 1041.926147,252.922607 
	C1041.919678,254.613037 1040.849854,256.299347 1040.274902,257.987610 
	C1034.530884,248.243958 1028.786865,238.500290 1022.935547,228.423370 
z"
    />
    <path
      fill="#8221DC"
      opacity="1.000000"
      stroke="none"
      d="
M1040.360840,258.312256 
	C1040.849854,256.299347 1041.919678,254.613037 1041.926147,252.922607 
	C1042.030762,225.732422 1041.970703,198.541611 1042.060059,171.351288 
	C1042.066650,169.345978 1042.927368,167.343460 1043.747803,165.148041 
	C1049.325439,173.956635 1054.546265,182.956802 1059.915771,192.707214 
	C1059.709473,201.049713 1059.097290,208.640335 1059.048828,216.234573 
	C1058.910034,237.980392 1059.032837,259.727814 1058.950928,281.474213 
	C1058.942505,283.729187 1058.252075,285.981628 1057.878174,288.235229 
	C1052.067627,278.369110 1046.257202,268.502991 1040.360840,258.312256 
z"
    />
    <path
      fill="#7E24D7"
      opacity="1.000000"
      stroke="none"
      d="
M1057.934570,288.576050 
	C1058.252075,285.981628 1058.942505,283.729187 1058.950928,281.474213 
	C1059.032837,259.727814 1058.910034,237.980392 1059.048828,216.234573 
	C1059.097290,208.640335 1059.709473,201.049713 1060.101074,192.984833 
	C1065.769409,202.017136 1071.400757,211.522034 1077.094604,221.365295 
	C1076.771484,223.959427 1076.056519,226.214279 1076.050171,228.471146 
	C1075.972778,255.755707 1076.019165,283.040588 1075.962402,310.325256 
	C1075.958008,312.436707 1075.419922,314.546997 1074.955566,317.084961 
	C1069.184570,307.980316 1063.587769,298.448578 1057.934570,288.576050 
z"
    />
    <path
      fill="#6F32C2"
      opacity="1.000000"
      stroke="none"
      d="
M1568.998901,393.187042 
	C1568.999390,413.701508 1568.999878,434.215942 1569.002686,455.172821 
	C1560.269165,454.396637 1551.533569,453.178040 1542.399170,451.385925 
	C1542.016846,431.475220 1542.033203,412.138092 1542.510010,392.716675 
	C1551.646729,392.817291 1560.322754,393.002167 1568.998901,393.187042 
z"
    />
    <path
      fill="#8221DC"
      opacity="1.000000"
      stroke="none"
      d="
M1488.826172,432.418213 
	C1480.300903,427.220856 1471.775513,422.023529 1463.105591,416.059631 
	C1462.973999,396.651886 1462.934814,378.010254 1463.078003,359.370026 
	C1463.092896,357.436646 1464.191040,355.511597 1465.036865,353.767395 
	C1473.555054,361.212433 1481.822632,368.472809 1490.063599,376.217896 
	C1490.024536,393.015839 1490.057129,409.329376 1489.948120,425.641998 
	C1489.932983,427.903107 1489.217407,430.159576 1488.826172,432.418213 
z"
    />
    <path
      fill="#881CE3"
      opacity="1.000000"
      stroke="none"
      d="
M1464.786499,353.582672 
	C1464.191040,355.511597 1463.092896,357.436646 1463.078003,359.370026 
	C1462.934814,378.010254 1462.973999,396.651886 1462.932373,415.779907 
	C1455.598145,410.280121 1448.292725,404.293579 1440.840088,398.041748 
	C1440.795410,375.061096 1440.815063,352.344818 1441.067383,329.631134 
	C1441.119995,324.898560 1442.033081,320.175568 1443.113770,315.455200 
	C1448.231079,329.544067 1455.557373,342.090210 1464.786499,353.582672 
z"
    />
    <path
      fill="#6A38B8"
      opacity="1.000000"
      stroke="none"
      d="
M219.999664,345.054626 
	C230.024719,344.998505 240.049789,344.942413 250.537354,345.435242 
	C250.999680,362.304596 250.999481,378.625000 250.999268,394.945404 
	C240.974121,395.002319 230.948990,395.059235 220.461899,394.565948 
	C219.999863,377.695374 219.999756,361.375000 219.999664,345.054626 
z"
    />
    <path
      fill="#7B28D2"
      opacity="1.000000"
      stroke="none"
      d="
M1489.063965,432.648315 
	C1489.217407,430.159576 1489.932983,427.903107 1489.948120,425.641998 
	C1490.057129,409.329376 1490.024536,393.015839 1490.406860,376.313507 
	C1499.517578,379.975189 1508.258301,384.025940 1516.999512,388.540527 
	C1517.000122,405.287048 1517.037842,421.569916 1516.953491,437.852142 
	C1516.942627,439.949219 1516.297729,442.042999 1515.946533,444.138306 
	C1507.064941,440.385040 1498.183228,436.631744 1489.063965,432.648315 
z"
    />
    <path
      fill="#7E24D7"
      opacity="1.000000"
      stroke="none"
      d="
M1477.054199,127.169876 
	C1477.036133,109.752388 1477.057861,92.334633 1476.941895,74.917809 
	C1476.929565,73.066559 1476.109619,71.220680 1475.315186,69.177101 
	C1483.707642,64.279564 1492.449341,59.577232 1501.621338,55.243259 
	C1502.367798,57.892506 1502.943970,60.171841 1502.957520,62.454529 
	C1503.041992,76.589607 1502.964600,90.725639 1503.046021,104.860748 
	C1503.055908,106.580269 1503.693848,108.296165 1503.812012,110.279243 
	C1494.740112,116.086456 1485.897095,121.628174 1477.054199,127.169876 
z"
    />
    <path
      fill="#7F32B4"
      opacity="1.000000"
      stroke="none"
      d="
M308.000793,394.945953 
	C298.308380,395.003235 288.615967,395.060516 278.461792,394.567261 
	C277.999817,377.695679 277.999634,361.374603 277.999451,345.053528 
	C287.691650,344.997833 297.383850,344.942139 307.538574,345.435059 
	C308.001007,362.304443 308.000916,378.625214 308.000793,394.945953 
z"
    />
    <path
      fill="#8E16EB"
      opacity="1.000000"
      stroke="none"
      d="
M143.353333,311.401123 
	C143.774704,322.362366 143.949097,333.495697 143.986801,344.629486 
	C144.042557,361.092499 144.001205,377.555908 144.001221,394.484192 
	C137.641846,395.007080 131.282455,395.064880 124.461685,394.570862 
	C123.978790,373.954376 123.957275,353.889648 123.935768,333.824921 
	C130.305573,326.407745 136.675385,318.990540 143.353333,311.401123 
z"
    />
    <path
      fill="#6C35BE"
      opacity="1.000000"
      stroke="none"
      d="
M1111.620728,381.563995 
	C1111.993652,379.267273 1112.928101,377.301208 1112.934937,375.331909 
	C1113.030640,347.735046 1112.979858,320.137787 1113.040039,292.540741 
	C1113.044556,290.434692 1113.617554,288.329926 1114.115112,285.788147 
	C1119.263062,293.744080 1124.222290,302.136475 1129.287598,310.824249 
	C1128.928955,314.381531 1128.075928,317.641724 1128.061523,320.905640 
	C1127.949829,346.213898 1128.000000,371.522888 1128.001099,397.281860 
	C1120.337891,394.758209 1115.678711,388.700897 1111.620728,381.563995 
z"
    />
    <path
      fill="#752DCA"
      opacity="1.000000"
      stroke="none"
      d="
M1516.196167,444.397156 
	C1516.297729,442.042999 1516.942627,439.949219 1516.953491,437.852142 
	C1517.037842,421.569916 1517.000122,405.287048 1517.388794,388.533264 
	C1525.868164,389.641754 1533.958862,391.221344 1542.049561,392.800964 
	C1542.033203,412.138092 1542.016846,431.475220 1542.002197,451.261414 
	C1533.484375,449.358978 1524.965088,447.007477 1516.196167,444.397156 
z"
    />
    <path
      fill="#7032C3"
      opacity="1.000000"
      stroke="none"
      d="
M202.885529,322.708984 
	C202.123825,321.284302 201.082336,319.578186 201.072662,317.866180 
	C200.958755,297.712555 201.045685,277.557800 200.952988,257.403931 
	C200.937881,254.117447 200.309082,250.833786 199.923126,247.100830 
	C205.391403,240.200150 210.900452,233.747437 216.710236,227.147964 
	C217.673981,228.624069 218.904510,230.243530 218.914185,231.870255 
	C219.042694,253.491089 218.975800,275.113007 219.038132,296.734436 
	C219.044235,298.852356 219.582504,300.968750 219.923996,303.494476 
	C214.344513,310.076996 208.714798,316.250916 202.885529,322.708984 
z"
    />
    <path
      fill="#8E2CB2"
      opacity="1.000000"
      stroke="none"
      d="
M308.462830,395.031250 
	C308.000916,378.625214 308.001007,362.304443 308.000946,345.518860 
	C317.358948,344.997803 326.717072,344.941559 336.537598,345.434021 
	C336.999939,362.304169 336.999908,378.625641 336.999878,394.947144 
	C327.641541,395.003601 318.283234,395.060059 308.462830,395.031250 
z"
    />
    <path
      fill="#6F32C2"
      opacity="1.000000"
      stroke="none"
      d="
M1569.549561,37.861824 
	C1570.000000,58.276257 1570.000000,78.645233 1569.537476,99.563812 
	C1561.714966,100.105095 1554.354980,100.096779 1546.996460,99.614258 
	C1546.996948,79.740570 1546.996094,60.341084 1546.995117,40.941597 
	C1554.363037,39.899857 1561.731079,38.858112 1569.549561,37.861824 
z"
    />
    <path
      fill="#8435B5"
      opacity="1.000000"
      stroke="none"
      d="
M775.931702,108.692123 
	C775.954468,96.556206 775.927063,84.419800 776.044800,72.284805 
	C776.066895,70.004135 776.668762,67.729088 777.082031,65.003845 
	C791.898315,71.229134 805.052307,80.373367 817.754333,90.756165 
	C817.610718,96.439003 817.312866,101.829498 816.654541,107.860817 
	C802.839966,108.565132 789.385803,108.628632 775.931702,108.692123 
z"
    />
    <path
      fill="#962AB1"
      opacity="1.000000"
      stroke="none"
      d="
M337.462097,395.031921 
	C336.999908,378.625641 336.999939,362.304169 336.999939,345.517792 
	C345.796967,345.052887 354.594055,345.052887 363.694702,345.052887 
	C363.694702,361.625305 363.694702,378.037567 363.694702,395.116760 
	C355.104401,395.116760 346.514343,395.116760 337.462097,395.031921 
z"
    />
    <path
      fill="#7436B6"
      opacity="1.000000"
      stroke="none"
      d="
M277.537231,344.969116 
	C277.999634,361.374603 277.999817,377.695679 277.999725,394.481567 
	C269.307373,395.003906 260.615265,395.061371 251.461227,395.032135 
	C250.999481,378.625000 250.999680,362.304596 250.999573,345.519379 
	C259.691162,344.997955 268.383087,344.941315 277.537231,344.969116 
z"
    />
    <path
      fill="#782CCD"
      opacity="1.000000"
      stroke="none"
      d="
M1504.040771,110.013733 
	C1503.693848,108.296165 1503.055908,106.580269 1503.046021,104.860748 
	C1502.964600,90.725639 1503.041992,76.589607 1502.957520,62.454529 
	C1502.943970,60.171841 1502.367798,57.892506 1501.989014,55.177670 
	C1509.690552,51.821125 1517.454834,48.898529 1525.609497,46.452953 
	C1526.000000,65.575836 1526.000122,84.221710 1525.637207,103.002380 
	C1518.196411,105.429367 1511.118652,107.721550 1504.040771,110.013733 
z"
    />
    <path
      fill="#752DCA"
      opacity="1.000000"
      stroke="none"
      d="
M1526.000244,102.867584 
	C1526.000122,84.221710 1526.000000,65.575836 1525.999023,46.460720 
	C1532.718872,44.288849 1539.439575,42.586231 1546.577759,40.912605 
	C1546.996094,60.341084 1546.996948,79.740570 1546.593262,99.741158 
	C1539.459106,101.184029 1532.729736,102.025803 1526.000244,102.867584 
z"
    />
    <path
      fill="#962AB1"
      opacity="1.000000"
      stroke="none"
      d="
M338.260651,172.778107 
	C337.647156,170.845291 337.050720,168.753159 337.042633,166.658737 
	C336.969727,147.776733 337.000000,128.894318 336.999817,109.549431 
	C348.522064,109.840569 357.422546,115.003548 362.130981,125.650627 
	C366.668518,135.911148 363.451965,145.068848 356.241150,153.080078 
	C350.363617,159.610031 344.448822,166.106430 338.260651,172.778107 
z"
    />
    <path
      fill="#960FF7"
      opacity="1.000000"
      stroke="none"
      d="
M124.997002,160.836090 
	C117.540939,160.836090 110.084877,160.836090 102.232391,160.836090 
	C102.232391,143.651810 102.232391,126.521729 102.232391,108.881401 
	C109.565437,108.881401 116.819954,108.881401 124.536972,109.431442 
	C124.998650,126.933014 124.997826,143.884552 124.997002,160.836090 
z"
    />
    <path
      fill="#8E16EB"
      opacity="1.000000"
      stroke="none"
      d="
M125.461136,160.906342 
	C124.997826,143.884552 124.998650,126.933014 124.998993,109.516411 
	C131.690598,108.995056 138.382690,108.938766 145.537109,109.432114 
	C145.998657,126.933098 145.997849,143.884430 145.997040,160.835754 
	C139.306458,160.882706 132.615860,160.929642 125.461136,160.906342 
z"
    />
    <path
      fill="#960FF7"
      opacity="1.000000"
      stroke="none"
      d="
M123.629982,333.977386 
	C123.957275,353.889648 123.978790,373.954376 124.000061,394.484253 
	C114.228706,395.411743 106.772896,391.179504 102.242889,382.804016 
	C97.060120,373.221741 98.638519,363.627991 105.121742,355.229340 
	C110.789238,347.887421 117.226822,341.139954 123.629982,333.977386 
z"
    />
    <path
      fill="#8023D8"
      opacity="1.000000"
      stroke="none"
      d="
M591.671021,64.384537 
	C592.003235,78.602417 592.001648,92.903038 591.545288,107.780899 
	C583.074097,108.308380 575.057800,108.258629 567.026367,107.727348 
	C567.003113,97.818184 566.994934,88.390549 566.986755,78.962921 
	C575.103577,74.131042 583.220459,69.299164 591.671021,64.384537 
z"
    />
    <path
      fill="#861EE0"
      opacity="1.000000"
      stroke="none"
      d="
M146.460297,160.905884 
	C145.997849,143.884430 145.998657,126.933098 145.999100,109.516731 
	C151.358490,108.995132 156.718231,108.938560 162.539001,109.432320 
	C162.999771,126.933464 162.999542,143.884277 162.999313,160.835098 
	C157.640717,160.882065 152.282135,160.929031 146.460297,160.905884 
z"
    />
    <path
      fill="#7B28D2"
      opacity="1.000000"
      stroke="none"
      d="
M192.996887,160.833740 
	C188.305313,160.880219 183.613754,160.926682 178.460770,160.445984 
	C177.999008,142.963913 177.998657,126.008995 177.998306,109.054092 
	C182.691803,108.997452 187.385300,108.940811 192.538559,109.434341 
	C192.997833,126.934258 192.997360,143.884003 192.996887,160.833740 
z"
    />
    <path
      fill="#8023D8"
      opacity="1.000000"
      stroke="none"
      d="
M177.538910,108.968605 
	C177.998657,126.008995 177.999008,142.963913 177.999207,160.376358 
	C173.306946,160.880463 168.614838,160.927017 163.461029,160.904343 
	C162.999542,143.884277 162.999771,126.933464 163.000000,109.517693 
	C167.693176,108.996208 172.386337,108.939667 177.538910,108.968605 
z"
    />
    <path
      fill="#6F32C2"
      opacity="1.000000"
      stroke="none"
      d="
M221.538467,108.967468 
	C221.998276,126.008392 221.998642,142.964340 221.998734,160.377960 
	C217.306427,160.881134 212.614395,160.926666 207.460693,160.445404 
	C206.998581,142.963684 206.998169,126.008766 206.997757,109.053841 
	C211.691513,108.996727 216.385269,108.939613 221.538467,108.967468 
z"
    />
    <path
      fill="#6F32C2"
      opacity="1.000000"
      stroke="none"
      d="
M219.540222,344.968567 
	C219.999756,361.375000 219.999863,377.695374 219.999817,394.480591 
	C215.305969,395.002380 210.612289,395.059357 205.459137,394.566010 
	C204.999619,377.695343 204.999573,361.375031 204.999512,345.054688 
	C209.693268,344.997314 214.387039,344.939911 219.540222,344.968567 
z"
    />
    <path
      fill="#752DCA"
      opacity="1.000000"
      stroke="none"
      d="
M206.539932,108.968170 
	C206.998169,126.008766 206.998581,142.963684 206.999100,160.376129 
	C202.638779,160.879913 198.278366,160.926178 193.457428,160.903091 
	C192.997360,143.884003 192.997833,126.934258 192.997940,109.519714 
	C197.359070,108.997444 201.720596,108.939972 206.539932,108.968170 
z"
    />
    <path
      fill="#752DCA"
      opacity="1.000000"
      stroke="none"
      d="
M204.551910,344.969360 
	C204.999573,361.375031 204.999619,377.695343 204.999588,394.480499 
	C201.964935,395.002380 198.930359,395.059448 195.448013,395.031860 
	C195.000168,378.625671 195.000076,362.304199 195.000122,345.517761 
	C198.034927,344.996552 201.069611,344.940277 204.551910,344.969360 
z"
    />
    <path
      fill="#8E2DE3"
      opacity="1.000000"
      stroke="none"
      d="
M566.650024,79.035507 
	C566.994934,88.390549 567.003113,97.818184 566.661011,107.892593 
	C562.192932,108.428871 558.075134,108.318367 553.957275,108.207870 
	C553.959595,102.008369 553.961853,95.808861 553.932983,89.113190 
	C558.039001,85.447372 562.176147,82.277740 566.650024,79.035507 
z"
    />
    <path
      fill="#FEFEFF"
      opacity="1.000000"
      stroke="none"
      d="
M553.501587,108.290131 
	C558.075134,108.318367 562.192932,108.428871 566.676147,108.374130 
	C575.057800,108.258629 583.074097,108.308380 591.545776,108.259659 
	C595.717041,108.128410 594.996460,110.830711 594.996887,113.056099 
	C595.002747,144.852539 595.615234,176.666122 594.728516,208.437820 
	C594.316406,223.202499 600.636108,233.092636 611.707153,241.572281 
	C618.289062,246.337509 625.409241,248.628815 633.573425,249.133972 
	C640.693237,249.178268 647.386414,249.268860 654.539673,249.259476 
	C660.693542,249.226257 666.387390,249.293030 672.540649,249.259644 
	C684.360291,249.224838 695.720581,249.290192 707.541260,249.256744 
	C717.694641,249.223892 727.387512,249.289841 737.541199,249.258698 
	C758.069214,249.861374 775.767639,231.692780 775.821289,210.120056 
	C775.904541,176.638382 775.852844,143.156372 775.895020,109.183319 
	C789.385803,108.628632 802.839966,108.565132 816.666626,108.339005 
	C820.498901,108.176361 823.958679,108.176361 828.000183,108.176361 
	C828.000183,110.623695 828.000183,112.394791 828.000183,114.165886 
	C828.000183,146.649094 828.208862,179.134583 827.852173,211.613876 
	C827.763916,219.650299 825.840698,227.666550 824.726746,236.058472 
	C819.581665,249.498459 812.977722,261.669373 803.124878,271.848572 
	C800.823547,274.226166 798.378235,276.464386 795.811646,279.014343 
	C786.623474,284.790558 777.622742,290.319153 768.313110,296.040466 
	C759.658386,299.732117 751.114868,302.192871 741.456177,301.298523 
	C731.346924,301.392303 721.695679,301.392303 710.637817,301.392303 
	C710.637817,332.763763 710.637817,364.152100 710.319885,395.830688 
	C694.644287,396.090424 679.286560,396.059875 663.464722,396.098267 
	C661.513916,396.065369 660.027222,395.963562 658.199646,395.838379 
	C658.199646,365.215179 658.199646,334.604614 658.199646,302.893097 
	C651.022583,302.893097 643.973938,302.893097 636.462646,302.975830 
	C628.930298,302.055878 621.860657,301.053223 614.394775,300.071136 
	C607.618103,297.655426 601.237671,295.219177 594.500610,292.665955 
	C588.024353,288.751526 581.904602,284.954102 575.466431,281.053650 
	C566.428894,273.345795 558.592590,264.987396 552.619263,254.566467 
	C548.738159,243.479294 545.038696,232.678864 541.512390,221.822220 
	C540.910400,219.968933 540.873474,217.875305 540.871399,215.892395 
	C540.835815,181.093735 540.844666,146.295013 540.857056,111.496300 
	C540.857300,110.678741 541.010010,109.861221 541.158691,108.372391 
	C545.151489,108.372391 549.098694,108.372391 553.501587,108.290131 
z"
    />
    <path
      fill="#FEFEFF"
      opacity="1.000000"
      stroke="none"
      d="
M1525.637207,103.002388 
	C1532.729736,102.025803 1539.459106,101.184029 1546.591797,100.215355 
	C1554.354980,100.096779 1561.714966,100.105095 1569.536987,100.028992 
	C1586.357788,100.000023 1602.716553,100.055466 1619.537598,100.026932 
	C1631.221802,101.055969 1642.443604,102.168983 1654.046387,103.299164 
	C1666.962158,108.878120 1679.497070,114.439903 1692.362305,120.070076 
	C1699.356812,126.046936 1706.335815,131.641830 1712.608643,137.940094 
	C1721.229614,146.596146 1726.572998,157.451553 1731.598877,168.803375 
	C1741.288208,198.331543 1744.060303,228.457291 1742.979370,259.038391 
	C1742.308716,278.010223 1739.530640,296.694275 1734.616699,315.456482 
	C1727.603027,336.949127 1715.692627,354.638580 1698.231201,368.953491 
	C1686.514648,377.578766 1674.253052,384.428619 1660.194092,388.496521 
	C1647.923706,390.116211 1635.920410,391.502258 1623.458496,392.973663 
	C1605.310913,393.075989 1587.622070,393.093018 1569.466064,393.148560 
	C1560.322754,393.002167 1551.646729,392.817291 1542.510010,392.716705 
	C1533.958862,391.221344 1525.868164,389.641754 1517.388184,388.069427 
	C1508.258301,384.025940 1499.517578,379.975189 1490.433594,375.828796 
	C1481.822632,368.472809 1473.555054,361.212433 1465.036865,353.767395 
	C1455.557373,342.090210 1448.231079,329.544067 1443.432861,315.206482 
	C1434.682129,274.510376 1433.635620,233.827011 1440.121704,193.065369 
	C1441.540527,184.149399 1445.314331,175.608139 1448.036621,166.531143 
	C1455.346313,151.653534 1464.378296,138.450806 1476.913086,127.465195 
	C1485.897095,121.628174 1494.740112,116.086456 1503.812012,110.279236 
	C1511.118652,107.721550 1518.196411,105.429367 1525.637207,103.002388 
M1515.964111,162.949814 
	C1501.104980,173.065994 1491.064331,186.914429 1490.296021,204.848450 
	C1489.129395,232.082306 1489.084839,259.439484 1490.252686,286.671875 
	C1491.045898,305.168915 1500.217285,320.231018 1516.846924,330.883118 
	C1524.854736,333.719391 1532.862671,336.555634 1541.604858,339.772369 
	C1549.736572,340.161987 1557.868286,340.551605 1566.920288,341.112061 
	C1581.946777,341.054718 1596.973145,340.997406 1612.920288,341.111633 
	C1624.719971,340.609802 1636.662842,341.307037 1648.733032,336.933014 
	C1671.934448,327.815704 1686.937988,311.113831 1688.635498,286.243774 
	C1690.429321,259.960999 1689.802124,233.435043 1688.843140,207.065857 
	C1688.003540,183.977814 1670.230835,163.046143 1647.270508,155.873215 
	C1635.760254,152.001572 1623.879761,152.443298 1611.079102,152.644928 
	C1596.052734,152.711105 1581.026367,152.777283 1565.079712,152.641663 
	C1557.025391,153.015335 1548.970947,153.389008 1540.087158,153.723068 
	C1531.556274,154.914658 1523.622070,157.655334 1515.964111,162.949814 
z"
    />
    <path
      fill="#6A38B8"
      opacity="1.000000"
      stroke="none"
      d="
M1611.999634,340.940094 
	C1596.973145,340.997406 1581.946777,341.054718 1566.460205,340.562103 
	C1566.000000,277.622589 1566.000000,215.233017 1566.000000,152.843460 
	C1581.026367,152.777283 1596.052734,152.711105 1611.539551,153.226410 
	C1611.999878,216.185287 1611.999756,278.562683 1611.999634,340.940094 
z"
    />
    <path
      fill="#7236B6"
      opacity="1.000000"
      stroke="none"
      d="
M1612.459961,341.025879 
	C1611.999756,278.562683 1611.999878,216.185287 1612.001343,153.326996 
	C1623.879761,152.443298 1635.760254,152.001572 1647.635010,156.482239 
	C1647.999390,217.035461 1647.999268,276.979675 1647.999146,336.923889 
	C1636.662842,341.307037 1624.719971,340.609802 1612.459961,341.025879 
z"
    />
    <path
      fill="#7B34B5"
      opacity="1.000000"
      stroke="none"
      d="
M1648.366089,336.928467 
	C1647.999268,276.979675 1647.999390,217.035461 1647.998047,156.634888 
	C1670.230835,163.046143 1688.003540,183.977814 1688.843140,207.065857 
	C1689.802124,233.435043 1690.429321,259.960999 1688.635498,286.243774 
	C1686.937988,311.113831 1671.934448,327.815704 1648.366089,336.928467 
z"
    />
    <path
      fill="#6F32C2"
      opacity="1.000000"
      stroke="none"
      d="
M1565.539795,152.742554 
	C1566.000000,215.233017 1566.000000,277.622589 1566.000000,340.476685 
	C1557.868286,340.551605 1549.736572,340.161987 1541.275269,339.085022 
	C1540.936035,276.852661 1540.926392,215.307663 1540.916626,153.762665 
	C1548.970947,153.389008 1557.025391,153.015335 1565.539795,152.742554 
z"
    />
    <path
      fill="#752DCA"
      opacity="1.000000"
      stroke="none"
      d="
M1540.501953,153.742874 
	C1540.926392,215.307663 1540.936035,276.852661 1540.908081,338.894775 
	C1532.862671,336.555634 1524.854736,333.719391 1516.979004,330.373322 
	C1517.407471,328.275208 1517.961426,326.687134 1517.962769,325.098572 
	C1518.007812,272.651855 1518.012817,220.205124 1517.941162,167.758469 
	C1517.938965,166.094986 1517.068237,164.432678 1516.602295,162.769836 
	C1523.622070,157.655334 1531.556274,154.914658 1540.501953,153.742874 
z"
    />
    <path
      fill="#7B28D2"
      opacity="1.000000"
      stroke="none"
      d="
M1516.283203,162.859818 
	C1517.068237,164.432678 1517.938965,166.094986 1517.941162,167.758469 
	C1518.012817,220.205124 1518.007812,272.651855 1517.962769,325.098572 
	C1517.961426,326.687134 1517.407471,328.275208 1516.715332,330.124573 
	C1500.217285,320.231018 1491.045898,305.168915 1490.252686,286.671875 
	C1489.084839,259.439484 1489.129395,232.082306 1490.296021,204.848450 
	C1491.064331,186.914429 1501.104980,173.065994 1516.283203,162.859818 
z"
    />
  </svg>
);

export const HamburgetMenuOpen = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    {...props}
  >
    <path fill="rgba(255, 255, 255, 0)" d="M0 0h24v24H0z" />
    <path
      fill="none"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={2}
      d="M3 17h18M3 12h18M3 7h18"
    />
  </svg>
);

export const HamburgetMenuClose = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="100%"
    height="100%"
    viewBox="0 0 24 24"
    {...props}
  >
    <path fill="rgba(255, 255, 255, 0)" d="M0 0h24v24H0z" />
    <g fill="none" fillRule="evenodd">
      <path d="M24 0v24H0V0h24ZM12.593 23.258l-.011.002-.071.035-.02.004-.014-.004-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01-.017.428.005.02.01.013.104.074.015.004.012-.004.104-.074.012-.016.004-.017-.017-.427c-.002-.01-.009-.017-.017-.018Zm.265-.113-.013.002-.185.093-.01.01-.003.011.018.43.005.012.008.007.201.093c.012.004.023 0 .029-.008l.004-.014-.034-.614c-.003-.012-.01-.02-.02-.022Zm-.715.002a.023.023 0 0 0-.027.006l-.006.014-.034.614c0 .012.007.02.017.024l.015-.002.201-.093.01-.008.004-.011.017-.43-.003-.012-.01-.01-.184-.092Z" />
      <path
        fill="currentColor"
        d="m12 14.121 5.303 5.304a1.5 1.5 0 0 0 2.122-2.122L14.12 12l5.304-5.303a1.5 1.5 0 1 0-2.122-2.121L12 9.879 6.697 4.576a1.5 1.5 0 1 0-2.122 2.12L9.88 12l-5.304 5.303a1.5 1.5 0 1 0 2.122 2.122L12 14.12Z"
      />
    </g>
  </svg>
);
