import Footer from "../components/UI/Footer";
import Navbar1 from "../components/UI/Navbar1";
import Footer1 from "../components/UI/Footer1";
import { Outlet } from "react-router-dom";

function RootLayout() {
  return (
    <>
      <Navbar1 />
      <main>
        <Outlet />
      </main>
      <Footer />
      <Footer1 />
    </>
  );
}

export default RootLayout;
