import React from "react";
import { useState, useEffect } from "react";
import classes from "./Brands.module.css";
import Card from "../UI/Card";

const brands = [
  {
    id: 1,
    src: "https://firebasestorage.googleapis.com/v0/b/letzrent-5f5a3.appspot.com/o/Vendor%20Images%2FZoomcar%20Logo.png?alt=media&token=fe58e5dd-1ef1-4b98-b8c0-bf69ec0fbf9b",
  },
  {
    id: 2,
    src: "https://firebasestorage.googleapis.com/v0/b/letzrent-5f5a3.appspot.com/o/MyChoize-logo.0a3a4fd4.png?alt=media&token=65763d2b-7bc3-4ff2-ac55-2187d1ab2b42&_gl=1*1jlon8x*_ga*MzQ4MTg5NjA0LjE2NjM0MjgwMzU.*_ga_CW55HF8NVT*MTY5NzY0MjMzNi41MjIuMS4xNjk3NjQyODM4LjYwLjAuMA",
  },
  {
    id: 3,
    src: "https://firebasestorage.googleapis.com/v0/b/letzrent-5f5a3.appspot.com/o/LetzRent%20App%20Images%2FBrand%20Logo%2FLow%20Cars.png?alt=media&token=e3c27e15-76bb-4f6a-8461-c211163e1b31",
  },
  {
    id: 4,
    src: "https://firebasestorage.googleapis.com/v0/b/letzrent-5f5a3.appspot.com/o/Vendor%20Images%2FDoor%20Cars.png?alt=media&token=eb189d2b-f8f8-48bc-85c8-88f9af8e4796",
  },
  {
    id: 5,
    src: "https://firebasestorage.googleapis.com/v0/b/letzrent-5f5a3.appspot.com/o/Vendor%20Images%2FKMR%20(1).png?alt=media&token=41c5b40b-1b5c-4c34-b3a3-5547527ac994",
  },
  {
    id: 6,
    src: "https://firebasestorage.googleapis.com/v0/b/letzrent-5f5a3.appspot.com/o/Vendor%20Images%2FKOS%20(1).png?alt=media&token=9f1586a9-648d-45cb-8956-c2911fc63897",
  },
  {
    id: 7,
    src: "https://firebasestorage.googleapis.com/v0/b/letzrent-5f5a3.appspot.com/o/Vendor%20Images%2FVUK.png?alt=media&token=4af09ff3-8e32-4831-b7d5-875e0f4e10d9",
  },
  {
    id: 8,
    src: "https://firebasestorage.googleapis.com/v0/b/letzrent-5f5a3.appspot.com/o/Vendor%20Images%2FZep%20Tep%20Logo-01%20(1).png?alt=media&token=5395c109-3c1f-4e6e-adea-d37989a5af16&_gl=1*15k7tsa*_ga*MzQ4MTg5NjA0LjE2NjM0MjgwMzU.*_ga_CW55HF8NVT*MTY5OTU0MjQzOC42MDkuMS4xNjk5NTQyNDY5LjI5LjAuMA..",
  },
  {
    id: 9,
    src: "https://media-exp1.licdn.com/dms/image/C4E0BAQEfgt3FTLDTRg/company-logo_200_200/0/1519884154917?e=2159024400&v=beta&t=N-2N0KC4-njG5O1IwI-y5-ktUKHxMuJmSYd5HExkwK0",
  },
  {
    id: 10,
    src: "https://www.wowcarz.in/resources/victoria/images/wowcarz-logo.png",
  },
  {
    id: 11,
    src: "https://play-lh.googleusercontent.com/DZvXCKxZhMPrhGv8Gk699So1UuFUeol6WnmlCrEaVH02C1-ODlPMPtm8Mc6EEI8iT3s",
  },
  {
    id: 12,
    src: "https://firebasestorage.googleapis.com/v0/b/letzrent-5f5a3.appspot.com/o/LetzRent%20App%20Images%2FBrand%20Logo%2FKaryana%20Car%20Rental.png?alt=media&token=a8ffdc5f-d157-403c-8d49-9c911e54fcdc",
  },
  {
    id: 13,
    src: "https://firebasestorage.googleapis.com/v0/b/letzrent-5f5a3.appspot.com/o/LetzRent%20App%20Images%2FBrand%20Logo%2FCarronrent.png?alt=media&token=045d4afd-a065-4554-b1bc-85130149b07e",
  },
  {
    id: 14,
    src: "https://firebasestorage.googleapis.com/v0/b/letzrent-5f5a3.appspot.com/o/LetzRent%20App%20Images%2FBrand%20Logo%2FWhatsApp%20Image%202022-08-04%20at%2010.45.13%20PM.jpeg?alt=media&token=e3c00d49-eb95-40db-b795-f42688ceef7c",
  },
];

function Brands() {
  const [currentBrandIndex, setCurrentBrandIndex] = useState(0);

  // Use useEffect to update the current brand index every few seconds
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentBrandIndex(
        (currentIndex) => (currentIndex + 1) % brands.length
      );
    }, 2000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className={classes.brand}>
      <h2 className={classes.brandTitle}>Compare And Choose From</h2>

      <div className={classes.brandContainer}>
        {brands.map((brand, index) => (
          <Card
            key={brand.id}
            className={index === currentBrandIndex ? classes.activeCard : ""}
          >
            <img
              src={brand.src}
              alt="brandImg"
              className={classes.brandImg}
              loading="lazy"
            />
          </Card>
        ))}
      </div>
    </div>
  );
}

export default Brands;
