import React from "react";
import Terms from "../components/about/Terms";
import { useEffect } from "react";
import { Helmet } from "react-helmet";

function TermsPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Helmet>
        <link rel="canonical" href="https://zymo.app/terms" />
      </Helmet>
      <Terms />;
    </div>
  );
}

export default TermsPage;
