import React from "react";

import { Helmet } from "react-helmet";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { db } from "../../../src/firebase-config";
import { collection, query, where, onSnapshot } from "firebase/firestore";

import "./blogDetailPage.scss";

export function BlogDetailPage() {
  const { id } = useParams();
  const [filteredBlog, setFilteredBlog] = useState({});
  const blogsCollectionRef = collection(db, "blogs");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let blogSlug = id
    .split("-")
    .join(" ")
    .split("$")
    .join("?")
    .split("_")
    .join("-");

  const getBlogQuery = query(
    blogsCollectionRef,
    where("title", "==", blogSlug)
  );

  const blogQuery = onSnapshot(getBlogQuery, (Snapshot) => {
    let blog = Snapshot.docs.forEach((doc) => {
      setFilteredBlog({ ...doc.data(), id: doc.id });
      blogQuery();
    });
  });

  return (
    <div>
      <Helmet>
        <link rel="canonical" href={`https://zymo.app/blog/${id}`} />
      </Helmet>
      <div className="blog-details-container">
        <h2>{filteredBlog?.title}</h2>
        <div
          dangerouslySetInnerHTML={{ __html: filteredBlog?.description }}
        ></div>
      </div>
    </div>
  );
}
