import "./Slider.css";
import React from "react";
import BtnSlider from "./BtnSlider";
import { useState, useEffect } from "react";

export default function Slider() {
  const [slideIndex, setSlideIndex] = useState(1);

  const dataSlider = [
    {
      id: 1,
      image: "/Imgs/img1.png",
      name: [
        <span>
          &#x20B9;75/hr <br /> Baleno{" "}
        </span>,
      ],
    },
    {
      id: 2,
      image: "/Imgs/img2.png",
      name: [
        <span>
          &#x20B9;299/hr <br /> BMW{" "}
        </span>,
      ],
    },
    {
      id: 3,
      image: "/Imgs/img3.png",
      name: [
        <span>
          &#x20B9;109/hr <br /> Brezza{" "}
        </span>,
      ],
    },
    {
      id: 4,
      image: "/Imgs/img4.png",
      name: [
        <span>
          &#x20B9;62/hr <br /> Celerio{" "}
        </span>,
      ],
    },
    {
      id: 5,
      image: "/Imgs/img5.png",
      name: [
        <span>
          &#x20B9;79/hr <br /> Ciaz{" "}
        </span>,
      ],
    },
    {
      id: 6,
      image: "/Imgs/img6.png",
      name: [
        <span>
          &#x20B9;69/hr <br /> Dzire{" "}
        </span>,
      ],
    },
    {
      id: 7,
      image: "/Imgs/img7.png",
      name: [
        <span>
          &#x20B9;124/hr <br /> Ertiga{" "}
        </span>,
      ],
    },
    {
      id: 8,
      image: "/Imgs/img8.png",
      name: [
        <span>
          &#x20B9;79/hr <br /> Honda City{" "}
        </span>,
      ],
    },
    {
      id: 9,
      image: "/Imgs/img9.png",
      name: [
        <span>
          &#x20B9;119/hr <br /> Hyundai Venue{" "}
        </span>,
      ],
    },
    {
      id: 10,
      image: "/Imgs/img10.png",
      name: [
        <span>
          &#x20B9;199/hr <br /> Innova Crysta{" "}
        </span>,
      ],
    },
    {
      id: 11,
      image: "/Imgs/img11.png",
      name: [
        <span>
          &#x20B9;69/hr <br /> KUV{" "}
        </span>,
      ],
    },
    {
      id: 12,
      image: "/Imgs/img12.png",
      name: [
        <span>
          &#x20B9;62/hr <br /> Kwid{" "}
        </span>,
      ],
    },
    {
      id: 13,
      image: "/Imgs/img13.png",
      name: [
        <span>
          &#x20B9;219/hr <br /> Thar{" "}
        </span>,
      ],
    },
    {
      id: 14,
      image: "/Imgs/img14.png",
      name: [
        <span>
          &#x20B9;109/hr <br /> Nissan Magnite{" "}
        </span>,
      ],
    },
    {
      id: 15,
      image: "/Imgs/img15.png",
      name: [
        <span>
          &#x20B9;62/hr <br /> Spresso{" "}
        </span>,
      ],
    },
    {
      id: 16,
      image: "/Imgs/img16.png",
      name: [
        <span>
          &#x20B9;74/hr <br /> Swift{" "}
        </span>,
      ],
    },
    {
      id: 17,
      image: "/Imgs/img17.png",
      name: [
        <span>
          &#x20B9;99/hr <br /> TATA Nexon{" "}
        </span>,
      ],
    },
    {
      id: 18,
      image: "/Imgs/img18.png",
      name: [
        <span>
          &#x20B9;124/hr <br /> Triber{" "}
        </span>,
      ],
    },
    {
      id: 19,
      image: "/Imgs/img19.png",
      name: [
        <span>
          &#x20B9;199/hr <br /> XUV 500
        </span>,
      ],
    },
  ];

  useEffect(() => {
    const timer = setInterval(() => {
      nextSlide();
    }, 4000);
    return () => clearInterval(timer);
  }, [slideIndex]);

  function nextSlide() {
    if (slideIndex !== dataSlider.length) {
      setSlideIndex(slideIndex + 1);
    } else if (slideIndex === dataSlider.length) {
      setSlideIndex(1);
    }
  }

  function prevSlide() {
    if (slideIndex !== 1) {
      setSlideIndex(slideIndex - 1);
    } else if (slideIndex === 1) {
      setSlideIndex(dataSlider.length);
    }
  }

  return (
    <div className="car-carousel">
      <h2>Choose From Our Wide Range Of Cars</h2>

      <div className="container-slider">
        {dataSlider.map((obj, index) => {
          return (
            <div
              key={index}
              className={
                slideIndex === index + 1 ? "slide active-anim" : "slide"
              }
            >
              <img
                src={process.env.PUBLIC_URL + obj.image}
                alt={`Image ${index + 1}`}
              />

              <h2>{obj.name}</h2>
            </div>
          );
        })}

        <BtnSlider moveSlide={nextSlide} direction={"next"} />

        <BtnSlider moveSlide={prevSlide} direction={"prev"} />

        <div className="container-dots">
          {Array.from({ length: 20 }).map((item, index) => (
            <div
            // onClick={() => moveDot(index + 1)}
            // className={slideIndex === index + 1 ? "dot active" : "dot"}
            ></div>
          ))}
        </div>
      </div>
    </div>
  );
}
